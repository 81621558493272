import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges, OnInit,
} from '@angular/core';
import * as feather from 'feather-icons';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import Swal, { SweetAlertIcon } from 'sweetalert2';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { WsColmenaGeneralService } from 'src/app/providers/ws-colmena-general.service';

@Component({
  selector: 'app-sidebar-agenda',
  templateUrl: './sidebar-agenda.component.html',
  styleUrl: './sidebar-agenda.component.css',
})
export class SidebarAgendaComponent implements OnInit, OnChanges {
  @Input() NgModelAsistente: any;
  @Input() ValuesAsistente: any;
  @Input() visible: boolean = false;
  @Input() IsLoadPages: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input() FechaInicio_I: Date = new Date();
  @Input() FechaFin_I: Date = new Date();
  @Input() HoraInicio: string = '';
  @Input() HoraFin: string = '';

  @Input() ContactoId: number = 0; // Captura el parámetro recibido


  faSpinner = faSpinner;
  faCircleNotch = faCircleNotch;

  public descripcion_agenda: string = '';
  public titulo_agenda: string = '';
  public FechaRegisterCalendar: Date = new Date();
  public FechaInicio: Date = new Date();
  public FechaFin: Date = new Date();
  public HoraRegisterInit: Date = new Date();
  public LinkConferencia: boolean = false;
  public HoraRegisterFinish: Date = new Date();
  public AsistenteValueSelect: string[] = [];
  public form: FormGroup;

  nuevoCorreo: string = ''; // Para almacenar correos ingresados manualmente
  asistentesSeleccionados: any[] = []; // Almacena asistentes seleccionados


  public asistentesControl: FormControl = new FormControl();

  procesando: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public ServicioGeneral: WsColmenaGeneralService,
    private datePipe: DatePipe,
    private elementRef: ElementRef
  ) {
    this.form = this.formBuilder.group({});
  }

  ngOnChanges(changes: any) {
    // Sincronizar datos si cambian las entradas
    if (this.ValuesAsistente) {
      this.form.patchValue({
        AsistenteValueSelect: this.ValuesAsistente,
      });
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      titulo: ['', Validators.required],
      FechaRegisterCalendar: [new Date(), Validators.required],
      HoraRegisterInit: [new Date(), Validators.required],
      HoraRegisterFinish: [new Date(), Validators.required],
      AsistenteValueSelect: [[], Validators.required],
      descripcion_agenda: ['', Validators.required],
      LinkConferencia: [false],
    });

    this.setFocus('titulo');
    this.form.patchValue({
      AsistenteValueSelect: this.ValuesAsistente,
    });
  }

  // Función personalizada para comparar elementos
  compararAsistentes(opcion1: any, opcion2: any): boolean {
    // Si ambas opciones tienen un código, compáralos por código
    if (opcion1 && opcion2) {
      return opcion1.code === opcion2.code;
    }
    return false;
  }

  /*
  disableOption(value: string) {
    const index = this.NgModelAsistente.findIndex((item: any) => item.value === value);
    if (index !== -1) {
      this.NgModelAsistente[index].disabled = true;
    }
  }*/

  isDisabled(value: string): boolean {
    // Coloca aquí la lógica para determinar si un elemento debe estar deshabilitado
    // Por ejemplo, si el valor está en ValuesAsistente
    return this.ValuesAsistente.some(
      (item: { value: string }) => item.value === value
    );
  }

  disableSelectedOptions() {
    if (this.ValuesAsistente && this.ValuesAsistente.length > 0) {
      this.NgModelAsistente.forEach((group: any) => {
        group.items.forEach((item: any) => {
          //if (this.ValuesAsistente.includes(item.code)) {
          item.disabled = true;
          //}
        });
      });
    }
  }

  GenerarRegistroCalendario() {
    let FormSave = this.form.value;
    let anfitriones: string[] = [];

    console.log(FormSave.AsistenteValueSelect)

    this.FechaInicio = new Date(FormSave.FechaRegisterCalendar.getTime());
    this.FechaFin = new Date(FormSave.FechaRegisterCalendar.getTime());

    this.FechaInicio.setHours(FormSave.HoraRegisterInit.getHours());
    this.FechaInicio.setMinutes(FormSave.HoraRegisterInit.getMinutes());

    this.FechaFin.setHours(FormSave.HoraRegisterFinish.getHours());
    this.FechaFin.setMinutes(FormSave.HoraRegisterFinish.getMinutes());

    FormSave.AsistenteValueSelect.forEach((asistente: any) => {
      if (asistente && asistente.code) {
        anfitriones.push(asistente.code);
      }
    });

    let eventCalendario = {
      //id_contacto: this.ContactoId,
      descripcion_evento: FormSave.descripcion_agenda,
      titulo: FormSave.titulo,
      fecha_inicio: this.datePipe.transform(
        this.FechaInicio,
        'dd/MM/yyyy HH:mm:ss'
      ),
      hora_inicio: '',
      fecha_fin: this.datePipe.transform(this.FechaFin, 'dd/MM/yyyy HH:mm:ss'),
      hora_fin: '',
      anfitriones: anfitriones,
      linkMeet: FormSave.LinkConferencia,
    };

    if (this.form.valid) {
      this.procesando = true; // Activa el estado de "procesando"
      this.form.disable(); // Bloquea el formulario

      this.ServicioGeneral.RegistrarEventoCalendario(
        eventCalendario,
        Number(localStorage.getItem('id_empresa')),
        Number(localStorage.getItem('id_usuario')),
        this.ContactoId
      )
        .then((response: any) => {
          this.form.enable();
          this.procesando = false;

          if (response && response.cod === 7) {
            this.alertSweet('success', 'Registro exitoso', '');
            this.form.reset(); // Habilita el formulario nuevamente
            this.toggleSidebarAgenda();
          } else {
            this.alertSweet('info', 'Registro no se genero', '');
            return;
          }
        })
        .catch((ex: any) => {
          console.log(ex);

          this.form.enable();
          this.procesando = false;
        });
    } else {
      this.form.enable(); // Habilita el formulario nuevamente
      this.procesando = false;

      console.log('El formulario no es válido');
    }
  }

  toggleSidebarAgenda() {

    console.log(this.ContactoId);
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);

    console.log('Sidebar abierto con el parámetro:', this.ContactoId);

  }

  onHideSidebar() {
    this.toggleSidebarAgenda();
    console.log(this.ContactoId);
  }

  renderizarIconos() {
    setTimeout(() => {
      const elementosIcono =
        this.elementRef.nativeElement.querySelectorAll('.icon_button'); // Reemplaza '.feather-icon' por el selector de tus iconos
      elementosIcono.forEach((icono: any) => {
        feather.replace({
          // Reemplaza feather.replace con el método que utilizas para renderizar tus iconos Feather
          target: icono,
        });
      });
    }, 0);
  }

  setFocus(controlName: string) {
    const control = this.form.get(controlName);
    if (control) {
      const element = this.elementRef.nativeElement.querySelector(
        `[formcontrolname='${controlName}']`
      );
      if (element) {
        element.focus();
      }
    }
  }

  async alertSweet(tipo: SweetAlertIcon, titulo: string, texto: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: tipo,
      title: titulo,
      text: texto,
      //,footer:"Si lo necesita llame a soporte Colmena??"
    });
  }




  // Verificar si el elemento es precargado
  esElementoPrecargado(item: any): boolean {
    return item.precargado === true;
  }



  agregarCorreo(correo: any): void {
    const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let nuevoCorreo: string;

    // Determinar si el correo es un objeto o un string
    if (typeof correo === 'object' && correo.hasOwnProperty('code')) {
      nuevoCorreo = correo.code;
    } else if (typeof correo === 'object' && correo.hasOwnProperty('name')) {
      nuevoCorreo = correo.name;
    } else {
      alert('Por favor, ingrese un correo válido.');
      return;
    }

    // Validar el formato del correo
    if (regexCorreo.test(nuevoCorreo)) {
      const nuevoAsistente = { name: nuevoCorreo, code: nuevoCorreo };

      // Verificar si el correo ya existe en la lista de opciones
      const yaExisteEnOpciones = this.NgModelAsistente.some(
        (opcion: any) => opcion.code === nuevoCorreo
      );

      if (!yaExisteEnOpciones) {
        this.NgModelAsistente = [...this.NgModelAsistente, nuevoAsistente];
      }

      // Obtener y limpiar los valores actuales del formulario
      const seleccionados: any[] = this.form.get('AsistenteValueSelect')?.value || [];
      const seleccionadosNormalizados = seleccionados
        .filter((asistente: any) => asistente) // Filtrar valores `undefined`
        .map((asistente: any) =>
          typeof asistente === 'string'
            ? { name: asistente, code: asistente }
            : asistente
        );

      // Verificar si el correo ya está en los seleccionados
      const yaSeleccionado = seleccionadosNormalizados.some(
        (asistente: any) => asistente.code === nuevoCorreo
      );

      if (!yaSeleccionado) {
        seleccionadosNormalizados.push(nuevoAsistente);
      }

      // Actualizar el control del formulario
      this.form.patchValue({
        AsistenteValueSelect: seleccionadosNormalizados,
      });
    } else {
      alert('Por favor, ingrese un correo válido.');
    }
  }



  /*agregarCorreo(correo: any): void {
    const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let nuevoCorreo: string;

    // Determina si `correo` es un objeto o un string
    if (typeof correo === 'object' && correo.hasOwnProperty('code')) {
      nuevoCorreo = correo.code;
    } else if (typeof correo === 'string') {
      nuevoCorreo = correo;
    } else if (typeof correo === 'object' && correo.hasOwnProperty('name')) {
      nuevoCorreo = correo.name;
    }else{
      return
    }

    // Valida que el correo sea válido
    if (regexCorreo.test(nuevoCorreo)) {
      const nuevoAsistente = { name: nuevoCorreo, code: nuevoCorreo };

      // Verifica si ya existe en `NgModelAsistente`
      const yaExiste = this.NgModelAsistente.some(
        (opcion: any) => opcion.code === nuevoCorreo
      );

      // Agrega a `NgModelAsistente` si no existe
      if (!yaExiste) {
        this.NgModelAsistente = [...this.NgModelAsistente, nuevoAsistente];
      }

      // Obtén los seleccionados actuales sin valores duplicados
      const seleccionados = this.form.get('AsistenteValueSelect')?.value || [];
      const actualizado = [...seleccionados];

      // Verifica que no se repita el correo
      const yaSeleccionado = actualizado.some(
        (asistente: any) => asistente.code === nuevoCorreo
      );

      if (!yaSeleccionado) {
        actualizado.push(nuevoAsistente);
      }

      // Actualiza el control del formulario con los valores preservados
      this.form.patchValue({
        AsistenteValueSelect: actualizado,
      });
    } else {
      alert('Por favor, ingrese un correo válido.');
    }
  }*/


  // Método para remover asistentes seleccionados
  removerElemento(item: any): void {
    const seleccionados = this.form.get('AsistenteValueSelect')?.value || [];
    const actualizados = seleccionados.filter(
      (asistente: any) => asistente.code !== item.code
    );

    this.form.patchValue({
      AsistenteValueSelect: actualizados,
    });
  }

}
