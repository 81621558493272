<div class="row">
  <div class="col-12">
    <div class="card shadow-sm border-0">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <h2 class="card-title h5 text-primary mb-0">Configuración de Formularios</h2>
          <div class="d-flex align-items-center">
            <span class="badge me-3" [ngClass]="isAdministrator ? 'bg-admin' : 'bg-user'">
              <i class="me-1" style="width: 14px; height: 14px;" data-feather="user"></i>
              {{ isAdministrator ? 'ADMINISTRADOR' : 'USUARIO' }}
            </span>
            <button *ngIf="!showPreview && isAdministrator" 
              class="btn btn-sm btn-outline-primary me-2 d-inline-flex align-items-center" 
              (click)="openEnd(content)" 
              [disabled]="loading">
              <i class="me-1" data-feather="plus"></i> Nuevo campo
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <nav
              class="nav flex-column nav-tabs border-end"
              id="config-tabs"
              role="tablist"
            >
              <a
                class="nav-link active d-flex align-items-center"
                id="contact-tab"
                data-bs-toggle="pill"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="true"
              >
                <i class="link-icon me-2" data-feather="users"></i>
                Formulario Contacto
              </a>
              <a
                class="nav-link d-flex align-items-center"
                id="catalog-tab"
                data-bs-toggle="pill"
                href="#catalog"
                role="tab"
                aria-controls="catalog"
                aria-selected="false"
              >
                <i class="link-icon me-2" data-feather="database"></i>
                Catálogos
              </a>
            </nav>

            <div class="mt-4" *ngIf="!showPreview">
              <div class="card bg-light">
                <div class="card-body">
                  <h5 class="h6 text-uppercase mb-3">Acciones rápidas</h5>
                  <div class="d-grid gap-2">
                    <button class="btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center" (click)="togglePreview()">
                      <i class="link-icon me-1" data-feather="eye"></i> Vista previa
                    </button>
                  </div>

                  <h6 class="mt-4 mb-2 text-uppercase text-muted small">Consejos</h6>
                  <ul class="small mb-0 ps-3">
                    <li class="mb-2">Arrastra y suelta para reordenar campos.</li>
                    <li class="mb-2">Haz clic en una celda para editarla.</li>
                    <li class="mb-2">Los campos inactivos o no visibles no aparecerán en el formulario.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="tab-content border p-4 rounded h-100" id="config-tabs-content">
              <div
                class="tab-pane fade show active"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <!-- Sección de vista previa -->
                <div *ngIf="showPreview" class="mb-4">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h4 class="h5 text-primary mb-0">Vista previa del formulario</h4>
                    <button class="btn btn-sm btn-outline-secondary" (click)="togglePreview()">
                      <i class="link-icon me-1" data-feather="edit-2"></i> Volver al editor
                    </button>
                  </div>
                  <div class="bg-light border rounded p-4 preview-container">
                    <div #previewForm></div>
                  </div>
                </div>

                <!-- Sección de edición -->
                <div *ngIf="!showPreview">
                  <!-- Acciones y estado de cambios -->
                  <div class="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                    <div class="mb-2 mb-md-0">
                      <span *ngIf="hasChanges" class="badge bg-warning me-2">
                        <i class="link-icon me-1" data-feather="alert-circle"></i> Cambios pendientes
                      </span>
                      <span *ngIf="loading" class="badge bg-info">
                        <i class="link-icon me-1" data-feather="loader"></i> Cargando...
                      </span>
                    </div>
                    <div class="btn-toolbar">
                      <button
                        *ngIf="hasChanges && isAdministrator"
                        (click)="guardarCambios()"
                        [disabled]="loading"
                        type="button"
                        class="btn btn-success d-inline-flex align-items-center me-2"
                      >
                        <i class="link-icon me-1" data-feather="save"></i> Guardar
                      </button>
                      <button
                        *ngIf="hasChanges"
                        (click)="cancelarCambios()"
                        [disabled]="loading"
                        type="button"
                        class="btn btn-outline-danger d-inline-flex align-items-center"
                      >
                        <i class="link-icon me-1" data-feather="x-circle"></i> Cancelar
                      </button>
                    </div>
                  </div>

                  <div class="table-responsive border rounded">
                    <div>
                      <div #tabulator id="tabulator"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="catalog"
                role="tabpanel"
                aria-labelledby="catalog-tab"
              >
                <h3 class="h6 mb-3 text-primary">Catálogos Disponibles</h3>
                <p class="text-muted">
                  Los catálogos son utilizados en los campos de tipo Lista de Opciones y Selección Múltiple.
                </p>

                <div class="list-group">
                  <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                    <div>
                      <h6 class="mb-1">VENDEDOR</h6>
                      <p class="mb-0 small text-muted">Catálogo de asesores/vendedores</p>
                    </div>
                    <span class="badge bg-primary rounded-pill">Ver detalles</span>
                  </a>
                  <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                    <div>
                      <h6 class="mb-1">CIUDADES</h6>
                      <p class="mb-0 small text-muted">Lista de ciudades disponibles</p>
                    </div>
                    <span class="badge bg-primary rounded-pill">Ver detalles</span>
                  </a>
                  <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                    <div>
                      <h6 class="mb-1">ETAPA</h6>
                      <p class="mb-0 small text-muted">Etapas del proceso de ventas</p>
                    </div>
                    <span class="badge bg-primary rounded-pill">Ver detalles</span>
                  </a>
                  <a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                    <div>
                      <h6 class="mb-1">ORIGEN</h6>
                      <p class="mb-0 small text-muted">Origen o canal de captación</p>
                    </div>
                    <span class="badge bg-primary rounded-pill">Ver detalles</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-offcanvas>
  <div class="offcanvas-header border-bottom">
    <h4 class="offcanvas-title">Agregar Campo Personalizado</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="offcanvas.dismiss('Cross click')"
    ></button>
  </div>
  <div class="offcanvas-body">
    <div class="alert alert-info d-flex align-items-center" role="alert">
      <i class="link-icon me-2" data-feather="info"></i>
      <div>
        Complete los siguientes campos para personalizar el formulario de contactos. 
        Los campos marcados con <span class="text-danger">*</span> son obligatorios.
      </div>
    </div>

    <form [formGroup]="customFieldForm" (ngSubmit)="addCustomField()" class="mt-4">
      <!-- Tipo de Campo -->
      <div class="mb-3">
        <label for="tipoCampo" class="form-label">
          Tipo de Campo <span class="text-danger">*</span>
        </label>
        <select class="form-select" formControlName="cod_tipo_campo" id="tipoCampo">
          <option *ngFor="let tipo of fieldTypes" [value]="tipo.value">{{ tipo.label }}</option>
        </select>
        <div class="form-text text-muted">
          Selecciona el tipo de datos que contendrá este campo.
        </div>
      </div>

      <!-- Etiqueta -->
      <div class="mb-3">
        <label for="inputEtiqueta" class="form-label">
          Etiqueta visible <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="inputEtiqueta"
          formControlName="nom_etiqueta"
          placeholder="Ej: Nombre del Cliente"
          required
        />
        <div class="form-text text-muted">
          Nombre que verá el usuario en el formulario.
        </div>
      </div>

      <!-- Nombre Interno (generado automáticamente) -->
      <div class="mb-3">
        <label for="inputNombreInterno" class="form-label">
          Nombre Interno <small class="text-muted">(generado automáticamente)</small>
        </label>
        <input
          type="text"
          class="form-control bg-light"
          id="inputNombreInterno"
          formControlName="nom_nombre_campo"
          readonly
        />
        <div class="form-text text-muted">
          Identificador único usado internamente (generado a partir de la etiqueta).
        </div>
      </div>

      <!-- Tamaño máximo -->
      <div class="mb-3">
        <label for="tamanoMaximo" class="form-label">
          Tamaño máximo
        </label>
        <input
          type="number"
          class="form-control"
          id="tamanoMaximo"
          formControlName="num_tamaño_maximo"
          min="1"
          max="10000"
        />
      </div>

      <!-- Catálogo (solo visible para campos select/multiple) -->
      <div class="mb-3" *ngIf="customFieldForm.get('cod_tipo_campo')?.value === 'select' || customFieldForm.get('cod_tipo_campo')?.value === 'multiple'">
        <label for="catalogo" class="form-label">
          Catálogo <span class="text-danger">*</span>
        </label>
        <select class="form-select" formControlName="nom_catalogo_combo" id="catalogo">
          <option *ngFor="let cat of catalogos" [value]="cat.value">{{ cat.label }}</option>
        </select>
        <div class="form-text text-muted">
          Selecciona el catálogo de donde se cargarán las opciones.
        </div>
      </div>

      <!-- Tipo de validación -->
      <div class="mb-3">
        <label for="tipoValidacion" class="form-label">
          Tipo de validación
        </label>
        <select class="form-select" formControlName="nom_tipo_validacioon" id="tipoValidacion">
          <option *ngFor="let val of tiposValidacion" [value]="val.value">{{ val.label }}</option>
        </select>
      </div>

      <!-- Opciones adicionales -->
      <div class="row mt-4">
        <div class="col-md-6">
          <!-- Campo Obligatorio -->
          <div class="form-check form-switch mb-3">
            <input
              type="checkbox"
              class="form-check-input"
              id="campoObligatorio"
              formControlName="var_restricciones_bool"
            />
            <label class="form-check-label" for="campoObligatorio">
              Campo Obligatorio
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Campo Visible -->
          <div class="form-check form-switch mb-3">
            <input
              type="checkbox"
              class="form-check-input"
              id="campoVisible"
              formControlName="var_visibilidad_bool"
            />
            <label class="form-check-label" for="campoVisible">
              Campo Visible
            </label>
          </div>
        </div>
      </div>

      <!-- Botones de acción -->
      <div class="d-flex justify-content-end mt-4 border-top pt-3">
        <button type="button" class="btn btn-outline-secondary me-2" (click)="offcanvas.dismiss('Cancel')">
          Cancelar
        </button>
        <button type="submit" [disabled]="!customFieldForm.valid" class="btn btn-primary">
          <i class="link-icon me-1" data-feather="plus-circle"></i> Agregar Campo
        </button>
      </div>
    </form>
  </div>
</ng-template>
