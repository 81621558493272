import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {WsColmenaLoginService} from "./ws-colmena-login.service";
//import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class AuthColmenaService {

  private loginEndpoint = 'https://api.tu-servidor.com/login'; // Cambia por tu endpoint de autenticación

  arrayPosts: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private LoginServices: WsColmenaLoginService,
    //private messageService: MessageService,
  ) {}

  // Método para manejar login desde una URL con parámetros
  loginFromUrl(urlParams: string): void {
    const params = new URLSearchParams(urlParams);

    const username = params.get('username');
    const password = params.get('password');

    if (username && password) {
      this.login(username, password);
    } else {
      console.error('Faltan parámetros en la URL');
    }
  }

  // Método de login normal
  async login(username: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      usuario: username,
      password: password
    };


    try {
      const response = await this.LoginServices.LoginColmena(body);
      this.arrayPosts = response;

      if (this.arrayPosts.cod === 7) {
        this.storeSessionData(this.arrayPosts);
        this.router.navigate(['/home']);
      } else {
        /*this.messageService.add({
          severity: 'error',
          summary: 'Verificar',
          detail: this.arrayPosts.desc
        });*/
      }
    } catch (error) {
      /*this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error de conexión'
      });*/
    } finally {
      //this.loading = false;
    }



    /*this.http.post(this.loginEndpoint, body, { headers }).subscribe({
      next: (response: any) => {

        localStorage.setItem('authToken', response.token);

        this.router.navigate(['/dashboard']);
      },
      error: (err) => {
        console.error('Error en el login:', err);
      }
    });*/
  }

  // Método para verificar si el usuario está autenticado
  isAuthenticated(): boolean {
    return !!localStorage.getItem('authToken');
  }


  private storeSessionData(response: any) {
    // Convertir `dato1` de string JSON a objeto si es necesario
    let parsedDato1;
    try {
      parsedDato1 = JSON.parse(response.dato1);
    } catch (error) {
      console.error("Error al parsear dato1: ", error);
      /*this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No se pudo procesar la respuesta del servidor.'
      });*/
      return;
    }

    if (parsedDato1 && parsedDato1.empresa && parsedDato1.empresa.length > 0) {
      const empresa = parsedDato1.empresa[0];
      localStorage.setItem('sessionData', JSON.stringify(response));
      localStorage.setItem('id_empresa', empresa.id_empresa);
      localStorage.setItem('razon_social', empresa.razon_social);
      localStorage.setItem('estado_empresa', empresa.estado_empresa);
      localStorage.setItem('nombres', empresa.nombres);
      localStorage.setItem('apellidos', empresa.apellidos);
      localStorage.setItem('correo', empresa.correo);
      localStorage.setItem('id_usuario', empresa.id_registro);
      localStorage.setItem('logo', empresa.logo);
      localStorage.setItem('token', response.token);
    }
  }


}
