import { Component, Input, Output, EventEmitter  } from '@angular/core';
import { WsColmenaGeneralService } from 'src/app/providers/ws-colmena-general.service';

@Component({
  selector: 'app-sidebar-widgets-config',
  templateUrl: './sidebar-widgets-config.component.html',
  styleUrl: './sidebar-widgets-config.component.css'
})
export class SidebarWidgetsConfigComponent {

  public widgetscalendar: any;
  public selectedItemId: number = 0;


  @Input() style: any;
  @Input() styleClass: string = '';
  @Input() visible: boolean = false;
  @Input() blockScroll: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();
  constructor(
    public ServicioGeneral: WsColmenaGeneralService,
  ){
    this.widgetscalendar = [
      {
        id: 1,
        name: 'Google Calendario',
        image:
          '//static.hsappstatic.net/ui-images/static-2.650/optimized/google-gmail.svg',
        disabled: false,
      },
      {
        id: 2,
        name: 'Microsoft Outlook',
        image:
          '//static.hsappstatic.net/ui-images/static-2.650/optimized/outlook.svg',
        disabled: true,
      },
      {
        id: 3,
        name: 'Microsoft Exchange',
        image:
          '//static.hsappstatic.net/ui-images/static-2.650/optimized/exchange.svg',
        disabled: true,
      },
    ];
  }

  toggleSidebar() {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }

  selectItem(itemId: number) {
    this.selectedItemId = itemId;
  }

  getSelectedWidgetsCalendar() {
    let tipo = 'calendar';
    if (this.selectedItemId !== null) {
      this.ServicioGeneral.getDataGoogleCalendar(tipo).then((data: any) => {
        window.open(data.url, '_blank');
        this.visible = false;
      });
    } else {
      console.log('Ningún div seleccionado.');
    }
  }

}
