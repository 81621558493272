import {
  Component,
  ChangeDetectorRef,
  inject,
  TemplateRef,
  AfterViewInit,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CellComponent,
  ColumnDefinition,
  TabulatorFull,
  RowComponent
} from 'tabulator-tables';
import * as feather from 'feather-icons';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { WsColmenaGeneralService } from '../../../providers/ws-colmena-general.service';
import { ServiceMenuService } from 'src/app/providers/service-menu.service';
import { DropdownModule } from 'primeng/dropdown';
import { CampoFormulario } from '../../../models/CampoFormulario';
import { CommonModule } from '@angular/common';
import Swal from 'sweetalert2';
import { FormsService } from 'src/app/services/forms/forms.service';

@Component({
  selector: 'app-formularios',
  templateUrl: './formularios.component.html',
  styleUrls: ['./formularios.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, DropdownModule, CommonModule],
})
export class FormulariosComponent implements AfterViewInit, OnInit, AfterViewChecked {
  @ViewChild('tabulator', { static: false }) tabulatorContainer!: ElementRef;
  @ViewChild('previewForm', { static: false }) previewFormContainer!: ElementRef;
  table: any;
  // Configuración de la tabla
  public tableData: CampoFormulario[] = [];
  public originalTableData: CampoFormulario[] = [];
  public columnConfig: ColumnDefinition[] = [];
  public loading: boolean = false;
  public hasChanges: boolean = false;
  public showPreview: boolean = false;
  
  // Permisos de usuario
  public isAdministrator: boolean = true; // Por defecto mostrar como administrador hasta implementar la validación real
  public userRole: string = 'Administrador'; // Valor por defecto

  // Tipos de campo disponibles
  public fieldTypes = [
    { label: 'Texto', value: 'textfield' },
    { label: 'Correo', value: 'email' },
    { label: 'Teléfono', value: 'phoneNumber' },
    { label: 'Lista de Opciones', value: 'select' },
    { label: 'Área de Texto', value: 'textarea' },
    { label: 'Fecha', value: 'date' },
    { label: 'Número', value: 'number' },
    { label: 'Moneda', value: 'currency' },
    { label: 'Selección Múltiple', value: 'multiple' }
  ];

  // Catálogos disponibles
  public catalogos = [
    { label: '-- Sin Catálogo --', value: '' },
    { label: 'VENDEDOR', value: 'VENDEDOR' },
    { label: 'CIUDADES', value: 'CIUDADES' },
    { label: 'ETAPA', value: 'ETAPA' },
    { label: 'ORIGEN', value: 'origen' }
  ];

  // Tipos de validación
  public tiposValidacion = [
    { label: 'Sin validación', value: 'NI' },
    { label: 'Correo', value: 'CO' },
    { label: 'Teléfono', value: 'TE' },
    { label: 'Numérico', value: 'NU' },
    { label: 'Sólo Números', value: 'N' }
  ];

  // Formulario Reactivo para campos personalizados
  public customFieldForm: FormGroup;

  // Inyección de dependencias
  private offcanvasService = inject(NgbOffcanvas);

  // Campos básicos inamovibles
  private camposBasicosObligatorios: string[] = [
    'identificacion', 'nombres', 'apellidos', 'direccion', 'correo',
    'celular', 'asesor', 'etapa', 'origen', 'monto', 'fecha_registro', 'id_co'
  ];

  constructor(
    private fb: FormBuilder,
    private servicioGeneral: WsColmenaGeneralService,
    private changeRef: ChangeDetectorRef,
    private menuService: ServiceMenuService,
    private formsService: FormsService
  ) {
    // Configuración inicial
    this.loadTableData();
    
    // En un escenario real, aquí se cargaría el rol del usuario desde un servicio
    // this.checkUserRole();

    // Inicializar Formulario Reactivo
    this.customFieldForm = this.fb.group({
      cod_tipo_campo: ['textfield', Validators.required],
      nom_etiqueta: ['', Validators.required],
      nom_nombre_campo: [{ value: '', disabled: true }],
      var_restricciones_bool: [false],
      var_visibilidad_bool: [true],
      num_tamaño_maximo: [150],
      nom_tipo_validacioon: ['NI'],
      nom_catalogo_combo: [''],
      sts_default_txt: ['Default']
    });

    // Reaccionar a cambios en el tipo de campo
    this.customFieldForm.get('cod_tipo_campo')?.valueChanges.subscribe(tipo => {
      const catalogoControl = this.customFieldForm.get('nom_catalogo_combo');
      
      if (tipo === 'select' || tipo === 'multiple') {
        catalogoControl?.setValidators([Validators.required]);
      } else {
        catalogoControl?.clearValidators();
        catalogoControl?.setValue('');
      }
      
      catalogoControl?.updateValueAndValidity();
    });

    // Generar nombre interno automáticamente a partir de la etiqueta
    this.customFieldForm.get('nom_etiqueta')?.valueChanges.subscribe(etiqueta => {
      if (etiqueta) {
        // Convertir a minúsculas, reemplazar espacios por guiones bajos y eliminar caracteres especiales
        const nombreInterno = etiqueta.toLowerCase()
          .replace(/\s+/g, '_')
          .replace(/[^\w\s]/gi, '')
          .replace(/__+/g, '_');
        
        this.customFieldForm.get('nom_nombre_campo')?.setValue(nombreInterno);
      }
    });
  }

  // Método para verificar el rol del usuario (a implementar con un servicio real)
  checkUserRole(): void {
    // Ejemplo de implementación con un servicio real:
    // this.userService.getCurrentUserRole().subscribe(role => {
    //   this.isAdministrator = role === 'ADMIN' || role === 'SUPER_ADMIN';
    //   this.userRole = this.isAdministrator ? 'Administrador' : 'Usuario';
    // });
    
    // Por ahora, simplemente establecemos valores por defecto
    this.isAdministrator = true;
    this.userRole = 'Administrador';
  }

  ngOnInit(): void {
    this.menuService.setMenu('menuConfiguracion');
    setTimeout(() => {
      feather.replace();
    }, 100);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    }, 200);
  }

  // Actualización de cambios en la vista
  ngAfterViewChecked() {
    this.refreshIcons();
  }

  // Configuración de columnas
  private initializeColumnConfig(): void {
    this.columnConfig = [
      {
        title: '',
        rowHandle: true,
        formatter: 'handle',
        headerSort: false,
        frozen: true,
        width: 40,
        minWidth: 35,
        maxWidth: 45,
        responsive: 0,
      },
      {
        title: '',
        cssClass: 'column-0-p',
        headerSort: false,
        frozen: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        hozAlign: 'center',
        formatter: (cell) => {
          const rowData = cell.getRow().getData() as CampoFormulario;
          if (rowData.esInmovible) {
            return "<i class='link-icon text-muted' data-feather='lock'></i>";
          }
          return "<i class='link-icon' data-feather='trash-2'></i>";
        },
        cellClick: (e, cell) => {
          const rowData = cell.getRow().getData() as CampoFormulario;
          if (!rowData.esInmovible) {
            this.handleDelete(rowData);
          } else {
            this.showInfoAlert('Este campo es obligatorio y no puede ser eliminado');
          }
        },
        responsive: 0,
        visible: this.isAdministrator, // Solo visible para administradores
      },
      {
        title: '#',
        field: 'num_orden',
        editor: this.isAdministrator ? 'input' : undefined, // Solo editable para administradores
        headerSort: false,
        width: 45,
        minWidth: 40,
        maxWidth: 50,
        responsive: 1,
        validator: "number",
        cellEdited: (cell) => this.onCellChanged(cell),
      },
      {
        title: 'Tipo',
        field: 'txt_tipo_dato',
        editor: this.isAdministrator ? 'list' : undefined, // Solo editable para administradores
        editorParams: {
          values: this.fieldTypes.map(t => t.value)
        },
        headerSort: false,
        width: 110,
        minWidth: 100,
        maxWidth: 120,
        formatter: (cell) => {
          const tipo = cell.getValue();
          const nombre = this.fieldTypes.find(t => t.value === tipo)?.label || tipo;
          return nombre; // Solo mostrar el texto sin icono
        },
        responsive: 1,
        cellEdited: (cell) => {
          // Verificar si el nuevo valor es un tipo que requiere catálogo
          const newValue = cell.getValue();
          const rowData = cell.getRow().getData() as CampoFormulario;
          
          if (newValue !== 'select' && newValue !== 'multiple') {
            // Si el tipo no requiere catálogo, limpiamos el valor del catálogo
            rowData.nom_catalogo_combo = '';
            cell.getRow().update(rowData);
          } else if (!rowData.nom_catalogo_combo) {
            // Si requiere catálogo pero no tiene uno, seleccionamos uno por defecto
            rowData.nom_catalogo_combo = this.catalogos[1].value;
            cell.getRow().update(rowData);
          }
          
          this.onCellChanged(cell);
          this.refreshIcons();
        }
      },
      {
        title: 'Nombre interno',
        field: 'nom_nombre_campo',
        headerSort: false,
        minWidth: 120,
        maxWidth: 150,
        responsive: 1,
        formatter: (cell) => {
          const valor = cell.getValue();
          return `<span class="text-muted font-monospace">${valor}</span>`;
        }
      },
      {
        title: 'Etiqueta',
        field: 'nom_etiqueta',
        hozAlign: 'left',
        editor: this.isAdministrator ? 'input' : undefined, // Solo editable para administradores
        headerSort: false,
        minWidth: 120,
        maxWidth: 180,
        responsive: 2,
        formatter: (cell) => {
          const valor = cell.getValue();
          return `<span class="fw-medium">${valor}</span>`;
        },
        cellEdited: (cell) => {
          // Al editar la etiqueta, actualizar automáticamente el nombre interno
          const etiqueta = cell.getValue();
          const rowData = cell.getRow().getData() as CampoFormulario;
          
          // Solo actualizamos el nombre interno si no está ya establecido (para campos existentes)
          // Esto previene cambiar nombres internos de campos ya creados
          if (rowData.cod_campo_id) {
            this.onCellChanged(cell);
            return;
          }
          
          // Generar nombre interno a partir de la etiqueta
          if (etiqueta) {
            const nombreInterno = etiqueta.toLowerCase()
              .replace(/\s+/g, '_')
              .replace(/[^\w\s]/gi, '')
              .replace(/__+/g, '_');
            
            rowData.nom_nombre_campo = nombreInterno;
            cell.getRow().update(rowData);
          }
          
          this.onCellChanged(cell);
          this.refreshIcons();
        },
      },
      {
        title: 'Obligatorio',
        field: 'var_restricciones_bool',
        hozAlign: 'center',
        editor: this.isAdministrator ? 'tickCross' : undefined, // Solo editable para administradores
        formatter: 'tickCross',
        headerSort: false,
        minWidth: 90,
        maxWidth: 100,
        responsive: 2,
        cellEdited: (cell) => {
          const value = cell.getValue();
          const rowData = cell.getRow().getData() as CampoFormulario;
          rowData.var_restricciones = value ? 'O' : 'N';
          cell.getRow().update(rowData);
          this.onCellChanged(cell);
          this.refreshIcons();
        },
      },
      {
        title: 'Visible',
        field: 'var_visibilidad_bool',
        hozAlign: 'center',
        editor: this.isAdministrator ? 'tickCross' : undefined, // Solo editable para administradores
        formatter: 'tickCross',
        headerSort: false,
        minWidth: 80,
        maxWidth: 90,
        responsive: 2,
        cellEdited: (cell) => {
          const value = cell.getValue();
          const rowData = cell.getRow().getData() as CampoFormulario;
          rowData.var_visibilidad = value ? 'V' : 'O';
          cell.getRow().update(rowData);
          this.onCellChanged(cell);
          this.refreshIcons();
        },
      },
      {
        title: 'Catálogo',
        field: 'nom_catalogo_combo',
        editor: this.isAdministrator ? 'list' : undefined, // Solo editable para administradores
        editorParams: {
          values: this.catalogos.map(c => c.value)
        },
        formatter: (cell) => {
          const valor = cell.getValue();
          if (!valor) return "";
          
          // Verificar si este campo debería tener catálogo (tipo select o multiple)
          const rowData = cell.getRow().getData() as CampoFormulario;
          if (rowData.txt_tipo_dato !== 'select' && rowData.txt_tipo_dato !== 'multiple') {
            return "";
          }
          
          return `<span class="badge bg-info">${valor}</span>`;
        },
        headerSort: false,
        minWidth: 100,
        maxWidth: 120,
        responsive: 3,
        cellEdited: (cell) => {
          this.onCellChanged(cell);
          this.refreshIcons();
        },
      },
      {
        title: 'Estado',
        field: 'sts_estado',
        sorter: 'string',
        editor: this.isAdministrator ? 'list' : undefined, // Solo editable para administradores
        editorParams: {
          values: ['ACTIVO', 'INACTIVO'],
        },
        hozAlign: 'center',
        formatter: function (cell, formatterParams) {
          const estado = cell.getValue();
          return `<span class='badge ${estado === 'ACTIVO' ? 'bg-success' : 'bg-danger'
          }'>${estado}</span>`;
        },
        minWidth: 90,
        maxWidth: 100,
        responsive: 2,
        cellEdited: (cell) => {
          this.onCellChanged(cell);
          this.refreshIcons();
        },
      },
    ];
  }

  initializeTable(): void {
    this.initializeColumnConfig();
    if (this.table) {
      this.table.destroy(); // Destruir la tabla existente antes de crear una nueva
    }
    this.table = new TabulatorFull(this.tabulatorContainer.nativeElement, {
      data: this.tableData,
      columns: this.columnConfig,
      layout: "fitColumns",
      responsiveLayout: 'hide',
      resizableRows: true,
      selectable: true,
      movableRows: this.isAdministrator, // Solo permitir mover filas si es administrador
      movableRowsReceiver: this.isAdministrator ? 'add' : undefined,
      movableRowsSender: this.isAdministrator ? 'delete' : undefined
    });

    // Manejar el evento rowMoved solo si es administrador
    if (this.isAdministrator) {
      this.table.on("rowMoved", (row: any) => {
        this.updateRowOrder(row);
      });
    }

    // Aplicar iconos después de renderizar
    setTimeout(() => {
      this.refreshIcons();
    }, 100);
  }

  // Método para refrescar los iconos después de cada cambio
  refreshIcons(): void {
    setTimeout(() => {
      feather.replace();
    }, 50);
  }

  // Actualiza los índices de orden después de mover una fila
  updateRowOrder(row: any): void {
    // Solo permitir cambios si es administrador
    if (!this.isAdministrator) return;
    
    // Verificar si hay campos bloqueados que fueron movidos
    const rowData = row.getData() as CampoFormulario;
    if (rowData.esInmovible) {
      // Si es un campo inmovible, permitimos el reordenamiento
      // pero avisamos que algunos cambios pueden no guardarse
      this.showInfoAlert('Has movido un campo básico. Ten en cuenta que algunos atributos no podrán modificarse.');
    }
    
    this.hasChanges = true;
    // Obtenemos todos los datos actualizados
    const currentData = this.table.getData();
    // Actualizamos el orden de cada fila
    currentData.forEach((row: CampoFormulario, index: number) => {
      row.num_orden = index + 1;
    });
    // Refrescamos la tabla
    this.table.setData(currentData);
    this.refreshIcons();
  }

  // Carga de datos en la tabla
  private loadTableData(): void {
    this.loading = true;
    const idEmpresa = Number(localStorage.getItem('id_empresa'));
    const ruc = localStorage.getItem('ruc_empresa') || '';
    
    // Intentar primero con el nuevo servicio
    this.formsService.getCamposFormulario(idEmpresa, ruc)
      .subscribe({
        next: (response: any) => {
          if (response && Array.isArray(response.data)) {
            // Procesar los datos recibidos
            this.processTableData(response.data);
          } else {
            // Si el nuevo servicio devuelve un formato inesperado, intentamos con el servicio anterior
            this.loadTableDataOriginal(idEmpresa);
          }
        },
        error: (error) => {
          console.error('Error con el nuevo servicio, intentando con el anterior:', error);
          // Si el nuevo servicio falla, intentamos con el servicio anterior
          this.loadTableDataOriginal(idEmpresa);
        }
      });
  }
  
  // Método alternativo para cargar datos usando el servicio original
  private loadTableDataOriginal(idEmpresa: number): void {
    this.servicioGeneral.ListCampos(idEmpresa).then((data: any) => {
      this.processTableData(data);
    }).catch(error => {
      console.error('Error al cargar datos:', error);
      this.loading = false;
      this.showErrorAlert('Error al cargar datos: ' + error.message);
    });
  }
  
  // Procesar los datos obtenidos y configurar la tabla
  private processTableData(data: any[]): void {
    // Ordena los datos por el campo num_orden
    this.tableData = data.sort((a: CampoFormulario, b: CampoFormulario) => 
      a.num_orden - b.num_orden
    );

    // Marcar los campos básicos obligatorios como no eliminables
    this.tableData.forEach(campo => {
      campo.esInmovible = this.camposBasicosObligatorios.includes(campo.nom_nombre_campo);
      
      // Convertir estados de caracteres a valores booleanos y textual
      campo.var_restricciones_bool = campo.var_restricciones === 'O';
      campo.var_visibilidad_bool = campo.var_visibilidad === 'V';
      campo.sts_estado = campo.sts_estado === 'S' || campo.sts_estado === 'ACTIVO' ? 'ACTIVO' : 'INACTIVO';
      
      // Asegurar que tengamos el campo sts_default_txt
      if (!campo.sts_default_txt) {
        campo.sts_default_txt = campo.sts_default ? 'Default' : '';
      }
    });

    // Guardamos una copia de los datos originales
    this.originalTableData = JSON.parse(JSON.stringify(this.tableData));
    this.initializeTable();
    this.loading = false;
    this.changeRef.detectChanges();
    this.refreshIcons();
  }

  // Manejo de cambios en celdas
  onCellChanged(cell: CellComponent): void {
    // Solo registrar cambios si es administrador
    if (!this.isAdministrator) return;
    
    this.hasChanges = true;
  }

  // Guardar cambios en los campos del formulario
  guardarCambios(): void {
    // Solo permitir guardar si es administrador
    if (!this.isAdministrator) {
      this.showErrorAlert('No tiene permisos para guardar cambios.');
      return;
    }
    
    // Validar que los campos básicos estén presentes
    const camposActuales = this.table.getData();
    const camposObligatoriosFaltantes = this.verificarCamposObligatorios(camposActuales);
    
    if (camposObligatoriosFaltantes.length > 0) {
      this.showErrorAlert(`No se pueden eliminar campos obligatorios: ${camposObligatoriosFaltantes.join(', ')}`);
      return;
    }
    
    this.loading = true;
    const idEmpresa = Number(localStorage.getItem('id_empresa'));
    const ruc = localStorage.getItem('ruc_empresa') || '';
    
    // Obtenemos los datos actualizados de la tabla
    const updatedData = this.table.getData();
    
    // Preparamos los datos para enviar al backend
    updatedData.forEach((campo: CampoFormulario, index: number) => {
      campo.num_orden = index + 1;
      campo.sts_estado = campo.sts_estado === 'ACTIVO' ? 'S' : 'N';
      
      // Garantizar que los campos obligatorios mantengan sus propiedades
      if (this.camposBasicosObligatorios.includes(campo.nom_nombre_campo)) {
        campo.sts_default = 1;
      }
      
      // Validar y ajustar catálogos según el tipo de campo
      if (campo.txt_tipo_dato !== 'select' && campo.txt_tipo_dato !== 'multiple') {
        campo.nom_catalogo_combo = '';
      }
      
      // Convertir booleanos a caracteres para restricciones y visibilidad
      campo.var_restricciones = campo.var_restricciones_bool ? 'O' : 'N';
      campo.var_visibilidad = campo.var_visibilidad_bool ? 'V' : 'O';
    });
    
    // Intentar primero con el nuevo servicio
    this.formsService.actualizarCamposFormulario(updatedData, idEmpresa, ruc)
      .subscribe({
        next: (response: any) => {
          // Verificar la respuesta del backend
          if (response && response.estado === 'ok') {
            // Actualizamos los datos originales
            this.originalTableData = JSON.parse(JSON.stringify(updatedData));
            this.hasChanges = false;
            
            // Mostrar mensaje de éxito
            this.showSuccessAlert('Cambios guardados correctamente');
            
            // Recargar los datos para asegurar que tenemos los datos actualizados desde el backend
            this.loadTableData();
          } else {
            // Si el nuevo servicio falla, intentamos con el servicio anterior
            this.guardarConServicioAnterior(updatedData, idEmpresa);
          }
          
          this.loading = false;
          this.changeRef.detectChanges();
          this.refreshIcons();
        },
        error: (error) => {
          console.error('Error con el nuevo servicio, intentando con el anterior:', error);
          // Si el nuevo servicio falla, intentamos con el servicio anterior
          this.guardarConServicioAnterior(updatedData, idEmpresa);
        }
      });
  }
  
  // Método alternativo usando el servicio anterior
  private guardarConServicioAnterior(updatedData: CampoFormulario[], idEmpresa: number): void {
    this.servicioGeneral.guardarCamposFormulario(updatedData, idEmpresa)
      .then((response: any) => {
        // Verificar la respuesta del backend
        if (response && response.codigo === 1) {
          // Actualizamos los datos originales
          this.originalTableData = JSON.parse(JSON.stringify(updatedData));
          this.hasChanges = false;
          
          // Mostrar mensaje de éxito
          this.showSuccessAlert('Cambios guardados correctamente');
          
          // Recargar los datos para asegurar que tenemos los datos actualizados desde el backend
          this.loadTableData();
        } else {
          // Mostrar mensaje de error si la respuesta no es exitosa
          this.showErrorAlert('Error al guardar los cambios: ' + (response?.mensaje || 'Respuesta no válida del servidor'));
        }
        
        this.loading = false;
        this.changeRef.detectChanges();
        this.refreshIcons();
      })
      .catch(error => {
        console.error('Error al guardar cambios:', error);
        this.loading = false;
        // Mostrar mensaje de error
        this.showErrorAlert('Error al guardar los cambios: ' + error.message);
      });
  }

  // Verificar que los campos obligatorios estén presentes
  private verificarCamposObligatorios(campos: CampoFormulario[]): string[] {
    const nombresCamposActuales = campos.map(c => c.nom_nombre_campo);
    return this.camposBasicosObligatorios.filter(
      campo => !nombresCamposActuales.includes(campo)
    );
  }
  
  // Mensaje informativo
  showInfoAlert(message: string): void {
    Swal.fire({
      title: 'Información',
      text: message,
      icon: 'info',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  // Cancelar cambios y revertir a los datos originales
  cancelarCambios(): void {
    Swal.fire({
      title: '¿Cancelar cambios?',
      text: "Se perderán todos los cambios no guardados. ¿Desea continuar?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Sí, cancelar cambios',
      cancelButtonText: 'No, mantener cambios'
    }).then((result) => {
      if (result.isConfirmed) {
        // Revertir a los datos originales
        this.tableData = JSON.parse(JSON.stringify(this.originalTableData));
        this.hasChanges = false;
        this.initializeTable();
        
        // Notificar al usuario
        Swal.fire(
          'Cambios cancelados',
          'Los cambios han sido descartados.',
          'info'
        );
        
        this.refreshIcons();
      }
    });
  }

  // Manejo de la eliminación de filas
  handleDelete(rowData: any): void {
    // Solo permitir eliminar si es administrador
    if (!this.isAdministrator) {
      this.showErrorAlert('No tiene permisos para eliminar campos.');
      return;
    }
    
    // Verificar si es un campo básico obligatorio
    if (this.camposBasicosObligatorios.includes(rowData.nom_nombre_campo)) {
      this.showInfoAlert('Este campo es obligatorio y no puede ser eliminado');
      return;
    }
    
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¿Deseas eliminar este campo del formulario? Esta acción no se puede deshacer.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const index = this.tableData.findIndex((item) => item === rowData);
        if (index > -1) {
          this.tableData.splice(index, 1);
          this.hasChanges = true;
          this.initializeTable();
          
          // Reordenar los campos restantes
          this.tableData.forEach((campo, idx) => {
            campo.num_orden = idx + 1;
          });
          
          // Notificar al usuario
          Swal.fire(
            '¡Eliminado!',
            'El campo ha sido eliminado correctamente.',
            'success'
          );
          
          this.refreshIcons();
        }
      }
    });
  }

  // Mostrar mensajes con SweetAlert2
  showSuccessAlert(message: string): void {
    Swal.fire({
      title: '¡Éxito!',
      text: message,
      icon: 'success',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  showErrorAlert(message: string): void {
    Swal.fire({
      title: 'Error',
      text: message,
      icon: 'error',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#0d6efd'
    });
  }

  // Abrir modal de campo personalizado
  openEnd(content: TemplateRef<any>): void {
    // Solo permitir abrir el modal si es administrador
    if (!this.isAdministrator) {
      this.showErrorAlert('No tiene permisos para agregar campos.');
      return;
    }
    
    this.offcanvasService.open(content, { position: 'end', backdrop: true });
  }

  // Agregar un campo personalizado
  addCustomField(): void {
    // Solo permitir agregar si es administrador
    if (!this.isAdministrator) {
      this.showErrorAlert('No tiene permisos para agregar campos.');
      return;
    }
    
    if (this.customFieldForm.valid) {
      const formValues = this.customFieldForm.getRawValue(); // Incluye valores disabled
      
      // Verificar si el tipo requiere catálogo y asegurarse de que tenga uno
      if ((formValues.cod_tipo_campo === 'select' || formValues.cod_tipo_campo === 'multiple') && !formValues.nom_catalogo_combo) {
        this.showErrorAlert('Para campos de tipo Lista de Opciones o Selección Múltiple es necesario seleccionar un catálogo.');
        return;
      }
      
      // Crear el nuevo campo
      const newField: CampoFormulario = {
        num_orden: this.tableData.length + 1,
        nom_nombre_campo: formValues.nom_nombre_campo,
        nom_etiqueta: formValues.nom_etiqueta,
        txt_tipo_dato: formValues.cod_tipo_campo,
        var_restricciones_bool: formValues.var_restricciones_bool,
        var_restricciones: formValues.var_restricciones_bool ? 'O' : 'N',
        var_visibilidad_bool: formValues.var_visibilidad_bool,
        var_visibilidad: formValues.var_visibilidad_bool ? 'V' : 'O',
        num_tamaño_maximo: formValues.num_tamaño_maximo,
        nom_tipo_validacioon: formValues.nom_tipo_validacioon,
        nom_catalogo_combo: formValues.nom_catalogo_combo,
        sts_default: 1,
        sts_default_txt: formValues.sts_default_txt || 'Default',
        sts_estado: 'ACTIVO',
        num_formulario_id: 1
      };
      
      this.tableData.push(newField);
      this.hasChanges = true;
      this.initializeTable();
      
      // Resetear formulario
      this.customFieldForm.reset({
        cod_tipo_campo: 'textfield',
        var_restricciones_bool: false,
        var_visibilidad_bool: true,
        num_tamaño_maximo: 150,
        nom_tipo_validacioon: 'NI',
        nom_catalogo_combo: '',
        sts_default_txt: 'Default'
      });
      
      // Cerramos el offcanvas
      this.offcanvasService.dismiss();
      this.refreshIcons();
    }
  }

  // Alternar vista previa del formulario
  togglePreview(): void {
    this.showPreview = !this.showPreview;
    setTimeout(() => {
      feather.replace();
      if (this.showPreview) {
        this.renderPreview();
      } else {
        this.loadTableData();
      }
    }, 100);
  }

  // Renderizar vista previa del formulario
  renderPreview(): void {
    if (!this.previewFormContainer) return;
    
    const camposActivos = this.tableData
      .filter(campo => campo.sts_estado === 'ACTIVO' && campo.var_visibilidad_bool)
      .sort((a, b) => a.num_orden - b.num_orden);
    
    if (camposActivos.length === 0) {
      this.previewFormContainer.nativeElement.innerHTML = `
        <div class="alert alert-info">
          No hay campos visibles o activos para mostrar en el formulario.
        </div>
      `;
      return;
    }
    
    // Crear vista previa HTML
    let previewHtml = '';
    
    camposActivos.forEach(campo => {
      const required = campo.var_restricciones_bool ? 'required' : '';
      
      previewHtml += `<div class="mb-3">
        <label class="form-label">${campo.nom_etiqueta} ${campo.var_restricciones_bool ? '<span class="text-danger">*</span>' : ''}</label>`;
      
      switch (campo.txt_tipo_dato) {
        case 'textfield':
          previewHtml += `<input type="text" class="form-control" ${required} disabled>`;
          break;
        case 'email':
          previewHtml += `<input type="email" class="form-control" ${required} disabled>`;
          break;
        case 'phoneNumber':
          previewHtml += `<input type="tel" class="form-control" ${required} disabled>`;
          break;
        case 'select':
          previewHtml += `<select class="form-select" ${required} disabled>
            <option>-- Seleccione una opción --</option>
          </select>`;
          break;
        case 'textarea':
          previewHtml += `<textarea class="form-control" rows="3" ${required} disabled></textarea>`;
          break;
        case 'date':
          previewHtml += `<input type="date" class="form-control" ${required} disabled>`;
          break;
        case 'number':
          previewHtml += `<input type="number" class="form-control" ${required} disabled>`;
          break;
        case 'currency':
          previewHtml += `<div class="input-group">
            <span class="input-group-text">$</span>
            <input type="number" class="form-control" ${required} disabled>
          </div>`;
          break;
        case 'multiple':
          previewHtml += `<select class="form-select" multiple ${required} disabled>
            <option>-- Seleccione opciones --</option>
          </select>`;
          break;
        default:
          previewHtml += `<input type="text" class="form-control" ${required} disabled>`;
      }
      
      previewHtml += `</div>`;
    });
    
    // Botón de envío
    previewHtml += `<div class="mt-4">
      <button type="button" class="btn btn-primary" disabled>Guardar</button>
      <button type="button" class="btn btn-outline-secondary ms-2" disabled>Cancelar</button>
    </div>`;
    
    this.previewFormContainer.nativeElement.innerHTML = previewHtml;
  }
}
