import { Component } from '@angular/core';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrl: './not-available.component.css'
})
export class NotAvailableComponent {

}
