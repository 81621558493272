import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { environment } from 'src/environmen';
import { FormularioContacto } from '../models/FormularioContacto';
import { catchError } from 'rxjs/operators';
import { CondicionCredito } from '@modules/sales/pages/cotizaciones/cotizaciones.component';
@Injectable({
  providedIn: 'root',
})
export class WsColmenaGeneralService {

  constructor(public http: HttpClient) { }

  RegistrarWidgetsCalendar(
    code: string,
    usuario: number,
    id_empresa: number,
    codigo_widgets: number,
    path_credenciales: string,
    tipo_oauth: string
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `OAuth2/RegistroTokenOathGoogle?code=${code}&usuario=${usuario}&id_empresa=${id_empresa}&codigo_widgets=${codigo_widgets}&path_credenciales=${path_credenciales}&tipo_oauth=${tipo_oauth}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getListMail(usuario: number, id_empresa: number, NextPageToken: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `mail/messages?cod_usuario=${usuario}&id_empresa=${id_empresa}&NextPageToken=${NextPageToken}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getMailById(usuario: number, id_empresa: number, cod_mail: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `mail/getMessagetById?cod_usuario=${usuario}&id_empresa=${id_empresa}&cod_mail=${cod_mail}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  formulario(id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/formulario_contactos?id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListaEtapasContacto(id_empresa: number, id_contacto: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/getLineEtapasContacto?id_empresa=${id_empresa}&id_contacto=${id_contacto}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  formularioDatos(id_empresa: number, id_contacto: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/formulario_contactos_datos?id_empresa=${id_empresa}&id_contacto=${id_contacto}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListCampos(id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/campos_formulario?id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListEtapasContacto(id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/ListEtapasContacto?id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getDataGoogleCalendar(tipo: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiUrlV1 + `OAuth2/loginOAuth2?tipo_auth=${tipo}`)

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  VerificaConfiguracionCalendario(
    categoria: number,
    usuario: number,
    id_empresa: number
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `OAuth2/getConfiguracionWidgets?categoria=${categoria}&usuario=${usuario}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addNotesContact(notes: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(environment.apiUrlV1 + `notes/registerNote`, notes)

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  RegistrarLoteChangeAsesor(
    listForm: FormularioContacto[],
    id_empresa: number
  ) {
    const body = {
      formEdit: listForm,
      id_empresa: id_empresa,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          environment.apiUrlV1 + `contactos/registro_lote_asesor`,
          body
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getTemporalNote(id_usuario: number, id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `notes/getTemporalNotes?id_usuario=${id_usuario}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addTemporalNote(noteTemp: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          environment.apiUrlV1 + `notes/RegisterTemporalNotes`,
          noteTemp
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateTemporalNote(noteTemp: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(environment.apiUrlV1 + `notes/UpdateTemporalNotes`, noteTemp)

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteTemporalNote(id: number, id_usuario: number, id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          environment.apiUrlV1 +
          `notes/DeleteTemporalNotes?id=${id}&id_usuario=${id_usuario}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteNote(id: number, id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          environment.apiUrlV1 +
          `notes/deleteNote?id=${id}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateNote(note: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(environment.apiUrlV1 + `notes/updateNote`, note)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getAllNotesContacts(id_contacto: number, id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `notes/getAllNotes?id_contacto=${id_contacto}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  RegistrarEventoCalendario(
    event: any,
    id_empresa: number,
    id_usuario: number
  ) {
    const body = {
      calendario: event,
      id_empresa: id_empresa,
      id_usuario: id_usuario,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(environment.apiUrlV1 + `calendar/registrar_agenda`, body)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  save(json: string, id_empresa: number) {

    console.log(json);



    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/guardar?json=${json}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  update(json: string, id_empresa: number, num_contacto_id: number) {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          environment.apiUrlV1 +
          `contactos/actualizar?json=${json}&num_contacto_id=${num_contacto_id}&id_empresa=${id_empresa}`,
          {}
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListContactos(id_empresa: number, page: number = 1, size: number = 10, filter: string = '') {
    return this.http
      .get(`${environment.apiUrlV1}contactos/ListaContactos?id_empresa=${id_empresa}&page=${page}&size=${size}&filter=${filter}`)
      .toPromise();
  }


  ListGridColumns(id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/ListGridColumns?id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListTotalRegisterContacs(id_empresa: number, filter: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/getTotalRegister?id_empresa=${id_empresa}&filter=${filter}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getTotalFiles(id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `documents/getTotalFiles?id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  CreateFolder(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(environment.apiUrlV1 + `documents/createFolder`, data)

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  EditFolder(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(environment.apiUrlV1 + `documents/editFolder`, data)

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteFile(id: number, id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `documents/delete?fileId=${id}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  deleteFolder(id: number, id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `documents/deleteFolder?folderId=${id}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getTotalFilesByIdFolder(id_empresa: number, id_folder: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `documents/getTotalFilesByIDfolder?id_empresa=${id_empresa}&id_folder=${id_folder}`
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListComboAsesores(id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `contactos/lista_asesore?id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListCombos(id_empresa: number): Promise<CondicionCredito[]> {
    return new Promise((resolve, reject) => {
      this.http.get<CondicionCredito[]>(environment.apiUrlV1 + `generales/ConsultaCombos?id_empresa=${id_empresa}`)
        .subscribe(
          (response: CondicionCredito[]) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }



  ListUsuarios(
    id_empresa: number,
    filtro_busqueda_ini: string,
    tipo_busqueda: string,
    page: number,
    limit: number,
    filter: string
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `usuarios/ListaUsuarios?id_empresa=${id_empresa}&filtro_busqueda_ini=${filtro_busqueda_ini}&tipo_busqueda=${tipo_busqueda}&page=${page}&limit=${limit}&filter=${filter}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  Registrar_Actividad_Tarea(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(environment.apiUrlV1 + `actividades/tareas_contacto`, data)

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  saveFiles(
    file: File,
    folderId: number,
    id_usuario: number,
    id_empresa: number
  ) /*: Observable<any>*/ {
    const formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          environment.apiUrlV1 +
          `documents/upload/${folderId}/${id_usuario}/${id_empresa}`,
          formData
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  refitraUserClientyx(
    user: any,
    user_trx: number,
    id_empresa: number
  ) /*: Observable<any>*/ {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          environment.apiUrlV1 + `config/register/${user_trx}/${id_empresa}`,
          user
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getAllFolders(folderId: number, id_usuario: number, id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `documents/folders?id_usuario=${id_usuario}&id_carpeta=${folderId}&id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ListCampanias(id_empresa: number) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
          `campaigns/ListaLocalCampanias?id_empresa=${id_empresa}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ObtenerCatalogo(id_empresa: number, cod_catalogo: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.apiUrlV1}generales/ConsultaCatalogo?id_empresa=${id_empresa}&cod_catalogo=${cod_catalogo}`
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async ObtenerCatalogoCRM(id_empresa: number, cod_catalogo: string): Promise<any> {
    try {
      // Usa firstValueFrom para convertir el Observable en una promesa
      const response = await firstValueFrom(
        this.http.get(`${environment.apiUrlV1}generales/ConsultaCatalogoCRM`, {
          params: {
            id_empresa: id_empresa.toString(),
            cod_catalogo: cod_catalogo,
          },
        })
      );
      return response;
    } catch (error) {
      console.error('Error en ObtenerCatalogoCRM:', error);
      throw error;
    }
  }

  // Guardar ítems con observables para mejor manejo de errores
  GuardarItems(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrlV1}items`, data)
      .pipe(
        catchError(error => {
          console.error('Error en GuardarItems', error);
          throw error;
        })
      );
  }

  // Obtener ítems CRM
  getItemsCRM(idEmpresa: number, page: number, size: number, searchField: string, searchQuery: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrlV1}items`, {
      params: {
        idEmpresa: idEmpresa.toString(),
        page: page.toString(),
        size: size.toString(),
        searchField: searchField.toString(),
        searchQuery: searchQuery.toString(),
      }
    }).pipe(
      catchError(error => {
        console.error('Error en getItemsCRM', error);
        throw error;
      })
    );
  }


  // Actualizar ítem con Promise para mantener consistencia con tu método actual
  async actualizarItemCRM(data: any): Promise<any> {
    try {
      // Usa firstValueFrom para convertir el observable en una promesa
      return await firstValueFrom(this.http.put<any>(`${environment.apiUrlV1}items`, data));
    } catch (error) {
      console.error('Error en actualizarItemCRM', error);
      throw error;
    }
  }

  async guardarCotizacionCRM(cotizacion: any): Promise<any> {
    try {
      return await this.http.post<any>(`${environment.apiUrlV1}cotizacion`, cotizacion).toPromise();
    } catch (error) {
      console.error('Error al guardar la cotización CRM', error);
      throw error;
    }
  }

  getCotizacionesCRM(
    idEmpresa: number,
    page: number,
    size: number,
    searchField: string,
    searchQuery: string
  ): Observable<any> {
    return this.http.get<any>(`${environment.apiUrlV1}cotizacion`, {
      params: {
        empresaId: idEmpresa.toString(),
        page: page.toString(),
        size: size.toString(),
        searchField: searchField || '',
        searchQuery: searchQuery || '',
      },
    }).pipe(
      catchError(error => {
        console.error('Error al obtener cotizaciones', error);
        throw error;
      })
    );
  }

  facturarCotizacion(data: any): Observable<any> {
    return this.http.post<any>(`${environment.LocalapiUrlV1}cotizacion/facturarCotizacion`, data)
      .pipe(
        catchError(error => {
          console.error('Error al facturar la cotización', error);
          // Utilizar la función fábrica:
          return throwError(() => error);
        })
      );
  }




}
