import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-sidebar',
  templateUrl: './loader-sidebar.component.html',
  styleUrl: './loader-sidebar.component.css'
})
export class LoaderSidebarComponent {

}
