import {AfterViewInit,OnInit, Component } from '@angular/core';

import * as feather from 'feather-icons';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements
OnInit, AfterViewInit {

  ngOnInit(): void {}

  ngAfterViewInit() {

    feather.replace();
  }

}
