import {
  Component,
  ChangeDetectorRef,
  inject,
  TemplateRef,
  AfterViewInit,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  CellComponent,
  ColumnDefinition,
  TabulatorFull,
} from 'tabulator-tables';
import { GridConfigContactoComponent } from '../../../components/grid-config-contacto/grid-config-contacto.component';
import * as feather from 'feather-icons';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { WsColmenaGeneralService } from '../../../providers/ws-colmena-general.service';
import { ServiceMenuService } from 'src/app/providers/service-menu.service';
import { DropdownModule } from 'primeng/dropdown';
@Component({
  selector: 'app-formularios',
  templateUrl: './formularios.component.html',
  styleUrls: ['./formularios.component.css'],
  standalone: true,
  imports: [GridConfigContactoComponent, ReactiveFormsModule, DropdownModule],
})
export class FormulariosComponent implements AfterViewInit, OnInit {
  @ViewChild('tabulator', { static: false }) tabulatorContainer!: ElementRef;
  table: any;
  // Configuración de la tabla
  public tableData: Record<string, unknown>[] = [];
  public columnConfig: ColumnDefinition[] = [];

  // Formato de fecha
  public readonly dateFormat = 'DD/MM/YYYY';

  // Variable para mostrar actualizaciones
  public updateInfo = '{{nothing yet}}';

  // Formulario Reactivo para campos personalizados
  public customFieldForm: FormGroup;
  public fieldTypes = [
    { label: 'Texto', value: 'TX' },
    { label: 'Lista de Opciones', value: 'NY' },
    { label: 'Área de Texto', value: 'FL' },
  ];

  // Inyección de dependencias
  private offcanvasService = inject(NgbOffcanvas);


  constructor(
    private fb: FormBuilder,
    private servicioGeneral: WsColmenaGeneralService,
    private changeRef: ChangeDetectorRef,
    private menuService: ServiceMenuService
  ) {
    // Configuración inicial

    this.loadTableData();

    // Inicializar Formulario Reactivo
    this.customFieldForm = this.fb.group({
      tipoCampo: ['', Validators.required],
      etiqueta: ['', Validators.required],
      nombreInterno: ['', Validators.required],
      campoObligatorio: [false],
    });
  }

  ngOnInit(): void {
    this.menuService.setMenu('menuConfiguracion');
  }

  ngAfterViewInit() {
    feather.replace();
  }

  // Configuración de columnas
  private initializeColumnConfig(): void {

    var printIcon = function(cell: any, formatterParams: any){ //plain text value
      return "<i class='fa fa-print'></i>";
  };

    this.columnConfig = [
      {
        title: '',
        rowHandle: true,
        formatter: 'handle',
        headerSort: false,
        frozen: true,
        width: 45,
        minWidth: 35,
        maxWidth: 55,
        responsive: 0,
      },
      {
        title: '',
        cssClass: 'column-0-p',
        headerSort: false,
        frozen: true,
        width: 48,
        minWidth: 40,
        maxWidth: 60,
        hozAlign: 'center',
        formatter: function (cell, formatterParams) {
          return "<i class='link-icon' data-feather='trash-2'></i>";
        },
        cellClick: (e, cell) => this.handleDelete(cell.getRow().getData()),
        responsive: 0,
      },
      {
        title: 'Orden',
        field: 'num_orden',
        editor: 'input',
        headerSort: false,
        width: 60,
        minWidth: 50,
        maxWidth: 80,
        responsive: 1,
      },
      {
        title: 'Nombre',
        field: 'nom_nombre_campo',
        editor: 'list',
        editorParams: {
          autocomplete: true,
          allowEmpty: true,
          showListOnEmpty: true,
          valuesLookup: 'all',
        },
        headerSort: false,
        minWidth: 100,
        maxWidth: 150,
        responsive: 1,
      },
      {
        title: 'Label',
        field: 'nom_etiqueta',
        sorter: 'number',
        hozAlign: 'left',
        editor: true,
        headerSort: false,
        minWidth: 100,
        maxWidth: 150,
        responsive: 2,
      },
      {
        title: 'Definición',
        field: 'sts_default_txt',
        sorter: 'number',
        hozAlign: 'left',
        editor: true,
        headerSort: false,
        minWidth: 140,
        responsive: 2,
      },
      {
        title: 'Obligatorio',
        field: 'var_restricciones_bool',
        hozAlign: 'center',
        sorter: 'boolean',
        editor: true,
        formatter: 'tickCross',
        headerSort: false,
        minWidth: 120,
        maxWidth: 160,
        responsive: 2,
      },
      {
        title: 'Estado',
        field: 'sts_estado',
        sorter: 'boolean',
        editor: true,
        hozAlign: 'center',
        formatter: function (cell, formatterParams) {
          const estado = cell.getValue();
          return `<span class='badge ${
            estado === 'ACTIVO' ? 'bg-success' : 'bg-danger'
          }'>${estado}</span>`;
        },
        minWidth: 100,
        maxWidth: 140,
        responsive: 2,
      },
    ];
  }

  initializeTable(): void {
    this.initializeColumnConfig();
    if (this.table) {
      this.table.destroy(); // Destruir la tabla existente antes de crear una nueva
    }
    this.table = new TabulatorFull(this.tabulatorContainer.nativeElement, {
      data: this.tableData,
      columns: this.columnConfig,
      layout:"fitColumns",
      responsiveLayout: 'hide',
      resizableRows:true,
      selectable:true,
      movableRows:true,
    });
  }

  // Carga de datos en la tabla
  private loadTableData(): void {
    const idEmpresa = Number(localStorage.getItem('id_empresa'));
    this.servicioGeneral.ListCampos(idEmpresa).then((data: any) => {
      this.tableData = data;
      this.initializeTable();
      this.changeRef.detectChanges();
      feather.replace();
    });
  }

  // Manejo de cambios en celdas
  onCellChanged(event: CellComponent): void {
    this.updateInfo = JSON.stringify(event.getData());
  }

  // Manejo de tablas construidas
  onBuildTable(): void {
    console.log('Tabla Construida');
  }

  // Manejo de tablas procesadas
  onProcessedTable(): void {
    console.log('Tabla Procesada');
  }

  // Abrir modal de campo personalizado
  openEnd(content: TemplateRef<any>): void {
    this.offcanvasService.open(content, { position: 'end' });
  }

  // Agregar un campo personalizado
  addCustomField(): void {
    if (this.customFieldForm.valid) {
      const newField = this.customFieldForm.value;
      this.tableData.push(newField);
      this.initializeTable(); // Re-inicializar la tabla para mostrar los nuevos datos
      this.customFieldForm.reset();
    }
  }

  // Formato para el estado de los campos
  formatEstado(estado: boolean): string {
    return estado
      ? "<i class='link-icon' data-feather='check'></i>"
      : "<i class='link-icon' data-feather='x'></i>";
  }

  // Manejo de la eliminación de filas
  handleDelete(rowData: Record<string, unknown>): void {
    const index = this.tableData.findIndex((item) => item === rowData);
    if (index > -1) {
      this.tableData.splice(index, 1);
      this.initializeTable(); // Re-inicializar la tabla para reflejar los cambios
    }
  }
}
