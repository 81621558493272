import { Component , OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { WsColmenaGeneralService } from 'src/app/providers/ws-colmena-general.service';

@Component({
  selector: 'app-google-calendar-oauth',
  templateUrl: './google-calendar-oauth.component.html',
  styleUrl: './google-calendar-oauth.component.css'
})
export class GoogleCalendarOauthComponent {

  constructor(
    private route: ActivatedRoute,
    public ServicioGeneral: WsColmenaGeneralService
  ){

  }


  ngOnInit(): void {
    // Obtener los parámetros de la URL actual
    this.route.queryParams.subscribe(params => {
      // Acceder a los parámetros y realizar las operaciones necesarias
      const code = params['code'];

      alert('Aguanta');

      console.log(code);

      this.ServicioGeneral.RegistrarWidgetsCalendar(
        code,
        Number(localStorage.getItem('id_usuario')),
        Number(localStorage.getItem('id_empresa')),
        1,
        '/credentials.json',
        'calendar'
       ).then((respuesta: any) => {

         alert(respuesta);

        if (respuesta.confirmacion) {
          setTimeout(() => {
            window.close();
            window.opener.postMessage('AuthorizationCompleted', '*');
          }, 3000);
        }

      });

    });
  }

}
