<footer class="modal_footer wizard_footer">
    <div class="flex-footer">
        <div class="flex">
            <button data-selenium-test="cancel-quote-btn" aria-disabled="false"
                class="button_static button_link "
                data-button-use="link-on-bright" tabindex="0" type="button">Salir</button>
        </div>
        <div class="flex">
            <button
                class="button_static button_link" type="button">Guardado por última vez 3 de junio de 2024
            </button>
            <button
                class="button-default button_static button-primary  disabled button-disabled"
                type="button">
                <span>Siguiente</span>
                <span
                    class="button_static icon_low" data-icon-name="right">
                    <fa-icon [icon]="faCircleArrowRight" size="1x"></fa-icon>
                </span>
            </button>
        </div>
    </div>
</footer>