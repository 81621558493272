
<p-sidebar [style]="{'overflow-y': 'hidden'}" [closeOnEscape]="false" [showCloseIcon]="false" [blockScroll]="blockScroll" [(visible)]="visible" position="right" styleClass="w-30rem">
    <ng-template pTemplate="header">Calendario</ng-template>
    <ng-template pTemplate="content">
        <div class="vertical_spacing flex flex-column">
            <div class="mb-0 max-w-full w-full mt-4">
                <div *ngFor="let item of widgetscalendar" [class.selected-widgets-calendar]="item.id === selectedItemId"
                    [class.nodisponible]="item.disabled" (click)="selectItem(item.id)"
                    class="flex align-items-center justify-content-center private-button box box-content p-3 max-w-full w-full">
                    <div class="box-inner width-100" id="bsb-25">
                        <div class="text-center box-element-widgets flex">
                            <img alt="provider-icon-google-gmail" class="mr-4 h-3rem" src="{{item.image}}">{{item.name}}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div>
            <div class="flex">
                <button (click)="getSelectedWidgetsCalendar()" [class.nodisponible]="selectedItemId === 0"
                    class="button_static button-primary button-sm" [disabled]="selectedItemId === 0" type="button">
                    Conectar
                </button>
                <button (click)="toggleSidebar()" class="button_static button-secondary button-sm" type="button">
                    Cancelar
                </button>
            </div>
        </div>
    </ng-template>
</p-sidebar>