import { Component } from '@angular/core';


@Component({
  selector: 'app-mod-cotizador-footer',
  templateUrl: './mod-cotizador-footer.component.html',
  styleUrl: './mod-cotizador-footer.component.css',
})
export class ModCotizadorFooterComponent {


}
