<nav class="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
  <div class="container-fluid">
    <div class="nav-start-wrap">
    </div>
    <div class="nav-end-wrap">
      <ul class="navbar-nav flex-row">
        <li class="nav-item">
          <a routerLink="/general" class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover">
            <span class="icon">
              <span class=" position-relative">
                <span class="feather-icon">
                  <i data-feather="settings"></i>
                </span>
                <span class="badge badge-sm badge-soft-primary badge-sm badge-pill position-top-end-overflow-1">4</span>
              </span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/inbox" class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover">
            <span class="icon">
              <span class=" position-relative">
                <span class="feather-icon">
                  <i data-feather="inbox"></i>
                </span>
                <span class="badge badge-sm badge-soft-primary badge-sm badge-pill position-top-end-overflow-1">4</span>
              </span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <div class="dropdown dropdown-notifications">
            <a href="#" class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover dropdown-toggle no-caret"
              data-bs-toggle="dropdown" data-dropdown-animation role="button" aria-haspopup="true"
              aria-expanded="false"><span class="icon"><span class="position-relative"><span class="feather-icon"><i
                      data-feather="bell"></i></span><span
                    class="badge badge-success badge-indicator position-top-end-overflow-1"></span></span></span></a>
            <div class="dropdown-menu dropdown-menu-end p-0">
              <h6 class="dropdown-header px-4 fs-6">Notificaciones<a href="#"
                  class="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover"><span class="icon"><span
                      class="feather-icon"><i data-feather="settings"></i></span></span></a>
              </h6>
              <div data-simplebar class="dropdown-body  p-2">
                <!--a href="javascript:void(0);" class="dropdown-item">
                  <div class="media">
                    <div class="media-head">
                      <div class="avatar avatar-rounded avatar-sm">
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="notifications-text">Morgan Freeman accepted your invitation to join the team
                        </div>
                        <div class="notifications-info">
                          <span class="badge badge-soft-success">Collaboration</span>
                          <div class="notifications-time">Today, 10:14 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <div class="media">
                    <div class="media-head">
                      <div class="avatar  avatar-icon avatar-sm avatar-success avatar-rounded">
                        <span class="initial-wrap">
                          <span class="feather-icon"><i data-feather="inbox"></i></span>
                        </span>
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="notifications-text">New message received from Alan Rickman</div>
                        <div class="notifications-info">
                          <div class="notifications-time">Today, 7:51 AM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <div class="media">
                    <div class="media-head">
                      <div class="avatar  avatar-icon avatar-sm avatar-pink avatar-rounded">
                        <span class="initial-wrap">
                          <span class="feather-icon"><i data-feather="clock"></i></span>
                        </span>
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="notifications-text">You have a follow up with Jampack Head on Friday, Dec 19 at
                          9:30 am</div>
                        <div class="notifications-info">
                          <div class="notifications-time">Yesterday, 9:25 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <div class="media">
                    <div class="media-head">
                      <div class="avatar avatar-sm avatar-rounded">
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="notifications-text">Application of Sarah Williams is waiting for your approval
                        </div>
                        <div class="notifications-info">
                          <div class="notifications-time">Today 10:14 PM</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <div class="media">
                    <div class="media-head">
                      <div class="avatar avatar-sm avatar-rounded">
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="notifications-text">Winston Churchil shared a document with you</div>
                        <div class="notifications-info">
                          <span class="badge badge-soft-violet">File Manager</span>
                          <div class="notifications-time">2 Oct, 2021</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <div class="media">
                    <div class="media-head">
                      <div class="avatar  avatar-icon avatar-sm avatar-danger avatar-rounded">
                        <span class="initial-wrap">
                          <span class="feather-icon"><i data-feather="calendar"></i></span>
                        </span>
                      </div>
                    </div>
                    <div class="media-body">
                      <div>
                        <div class="notifications-text">Last 2 days left for the project to be completed</div>
                        <div class="notifications-info">
                          <span class="badge badge-soft-orange">Updates</span>
                          <div class="notifications-time">14 Sep, 2021</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-->
              </div>
              <div class="dropdown-footer"><a href="#"><u>Ver todas las notificaciones</u></a></div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <div class="dropdown ps-2">
            <a class=" dropdown-toggle no-caret" href="#" role="button" data-bs-display="static"
              data-bs-toggle="dropdown" data-dropdown-animation data-bs-auto-close="outside" aria-expanded="false">
              <div class="avatar avatar-primary avatar-sm avatar-rounded avatar-xs">
                <span class="initial-wrap">{{getInitials(nombres)}}</span>
              </div>
              <!--div class="avatar avatar-rounded avatar-xs">
                <div class="avatar avatar-primary avatar-sm avatar-rounded">
                  <span class="initial-wrap">{{getInitials(nombres)}}</span>
                </div>
              </div-->
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="p-2">
                <div class="media">
                  <div class="media-head me-2">
                    <div class="avatar avatar-primary avatar-sm avatar-rounded">
                      <span class="initial-wrap">{{getInitials(nombres)}}</span>
                    </div>
                  </div>
                  <div class="media-body">
                    <div class="dropdown">
                      <a href="#" class="d-block dropdown-toggle link-dark fw-medium" data-bs-toggle="dropdown"
                        data-dropdown-animation data-bs-auto-close="inside">{{nombres}}</a>
                    </div>
                    <div class="fs-10 lowercase">{{correo}}</div>
                    <a (click)="DoLogin()" class="cursor-pointer d-block fs-8 link-secondary">
                      <u>Salir</u>
                    </a>
                  </div>
                </div>
              </div>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">
                <span class="dropdown-icon feather-icon">
                  <i data-feather="credit-card"></i>
                </span>
                <span>Perfil</span>
              </a>
              <!--a class="dropdown-item" href="#">
                <span class="dropdown-icon feather-icon">
                  <i data-feather="check-square"></i>
                </span>
                <span>Subscriptions</span>
              </a-->
              <a class="dropdown-item" routerLink="/general">
                <span class="dropdown-icon feather-icon">
                  <i data-feather="settings"></i>
                </span>
                <span>Configuraciones</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Terminos y Condiciones</a>
              <a class="dropdown-item" href="#">Soporte</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>


<!--nav class="navbar">
    <a href="#" class="sidebar-toggler">
        <i data-feather="menu"></i>
    </a>
    <div class="navbar-content">

        <ul class="navbar-nav">
            <li class="nav-item dropdown">
                <a  href="../general" class="nav-link dropdown-toggle"> 
                    <i data-feather="settings"></i>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="appsDropdown" role="button" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i data-feather="grid"></i>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="messageDropdown" role="button"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i data-feather="mail"></i>
                </a>
                <div class="dropdown-menu p-0" aria-labelledby="messageDropdown">
                    <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                        <p>{{ 'CONFIGCONTACTOS.ETAPAS' | translate }}</p>
                        <a href="javascript:;" class="text-muted">{{ 'CONFIGCONTACTOS.ETAPAS' | translate }}</a>
                    </div>
                    <div class="p-1">
                    </div>
                    <div class="px-3 py-2 d-flex align-items-center justify-content-center border-top">
                        <a href="javascript:;">Ver todos</a>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="notificationDropdown" role="button"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i data-feather="bell"></i>
                    <div class="indicator">
                        <div class="circle"></div>
                    </div>
                </a>
                <div class="dropdown-menu p-0" aria-labelledby="notificationDropdown">
                    <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                        <p>0 Notifications</p>
                        <a href="javascript:;" class="text-muted">Ninguna</a>
                    </div>
                    <div class="p-1">
                    </div>
                    <div class="px-3 py-2 d-flex align-items-center justify-content-center border-top">
                        <a href="javascript:;"></a>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img class="wd-30 ht-30 rounded-circle" src="https://via.placeholder.com/30x30" alt="profile">
                </a>
                <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
                    <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
                        <div class="mb-3">
                            <img class="wd-80 ht-80 rounded-circle" src="https://via.placeholder.com/80x80" alt="">
                        </div>
                        <div class="text-center">
                            <p class="tx-16 fw-bolder">{{nombres}}</p>
                            <p class="tx-12 text-muted">{{correo}}</p>
                        </div>
                    </div>
                    <ul class="list-unstyled p-1">
                        <li class="dropdown-item py-2">
                            <a href="pages/general/profile.html" class="text-body ms-0">
                                <i class="me-2 icon-md" data-feather="user"></i>
                                <span>Perfil</span>
                            </a>
                        </li>
                        <li class="dropdown-item py-2">
                            <a href="javascript:;" class="text-body ms-0">
                                <i class="me-2 icon-md" data-feather="edit"></i>
                                <span>Cambiar Empresa</span>
                            </a>
                        </li>
                        <li class="dropdown-item py-2" (click)="DoLogin()" >
                            <a  class="text-body ms-0">
                                <i class="me-2 icon-md" data-feather="log-out"></i>
                                <span>Salir</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</nav-->