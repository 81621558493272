import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceMenuService {

  private currentMenu: string = 'menuPrincipal'; // Por defecto

  getCurrentMenu(): string {
    return this.currentMenu;
  }

  setMenu(menu: string): void {
    this.currentMenu = menu;
  }
  
}
