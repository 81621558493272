export const environment = {

    LocalapiURlv1Login: 'http://localhost:8080/ApiCrm-0.0.1/auth/',
    LocalapiUrlV1: 'http://localhost:8080/ApiCrm-0.0.1/crm/v1/',

    apiURlv1Login: 'https://api.appcolmena.com/ApiCrm/auth/',
    apiUrlV1: 'https://api.appcolmena.com/ApiCrm/crm/v1/',

  //intranet

  //  apiURlv1Login: 'http://192.168.100.230:8080/api/api/v1/',
  //  apiUrlV1: "http://192.168.100.230:8080/api/movil/v1/"

  //spacecloud
  //apiURlv1Login: 'https://appcondi.com/api/api/v1/',
  //apiUrlV1: 'https://appcondi.com/api/movil/v1/'

  //
  //424698412059-dbrdju8obfndo2q27v9gejhq9sf78uiu.apps.googleusercontent.com


};
