import { Component } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { WsColmenaLoginService } from 'src/app/providers/ws-colmena-login.service';
import { Message, MessageService } from 'primeng/api';


import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faUserTag } from '@fortawesome/free-solid-svg-icons';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
  providers: [MessageService],
})
export class LoginComponent {


  faUserTag = faUserTag;
  faUserCheck = faUserCheck;
  faUserSecret = faUserSecret;
  faUser = faUser;
  faKey = faKey;

  loginForm: FormGroup;
  arrayPosts: any;
  loading: boolean = false;

  validation_messages = {
    usuario: [{ type: 'required', message: 'Debe ingresar usuario' }],
    password: [{ type: 'required', message: 'Debe ingresar la clave' }],
  };

  messages: Message[] = []; 
  conexionInestable: boolean = true;

  hidden= false;
  colorFondoLayoutNews: string = '';
  conexionMsj:string = '';

  constructor(
    private router: Router,
    private LoginServices: WsColmenaLoginService,
    private messageService: MessageService,
  ) {
    this.loginForm = new FormGroup({
      usuario: new FormControl('', Validators.compose([Validators.required])),
      password: new FormControl(
        '',
        Validators.compose([Validators.minLength(5), Validators.required])
      ),
    });
  }

  ngOnInit(): void {
this.verificarEstabilidadVelocidadConexion();
   
  }


  async doLogin() {
    this.loading = true;
    try {

      const response = await this.LoginServices.LoginColmena(
        this.loginForm.value
      );
      
      this.arrayPosts = response;


      let JsonEmpresa = JSON.parse(this.arrayPosts.dato1)

      if (this.arrayPosts.cod === 7) {


        if(JsonEmpresa.length > 1){

        }else{
          
          localStorage.clear();
          localStorage.setItem('sessionData', JSON.stringify(this.arrayPosts));
          localStorage.setItem('id_empresa', JsonEmpresa.empresa[0].id_empresa);
          localStorage.setItem('razon_social', JsonEmpresa.empresa[0].razon_social);
          localStorage.setItem('estado_empresa', JsonEmpresa.empresa[0].estado_empresa);
          localStorage.setItem('nombres', JsonEmpresa.empresa[0].nombres);
          localStorage.setItem('apellidos', JsonEmpresa.empresa[0].apellidos);
          localStorage.setItem('correo', JsonEmpresa.empresa[0].correo);
          localStorage.setItem('id_usuario', JsonEmpresa.empresa[0].id_registro);
          localStorage.setItem('logo', JsonEmpresa.empresa[0].logo);
          localStorage.setItem('token', this.arrayPosts.token);
          this.handleSuccessfulLogin(this.arrayPosts);

        }

        

      } else {
        //this.messages = [{ severity: 'error', summary: 'Error', detail: this.arrayPosts.desc }]

        this.messageService.add({
          severity: 'error',
          summary: 'Verificar',
          detail:
          this.arrayPosts.desc,
        });

      }
    } catch (error) {
      this.messages = [{ severity: 'error', summary: 'Error', detail: 'ERROR DE CONEXION' }]
    } finally {
      this.loading = false;
    }
  }

  private handleSuccessfulLogin(response: any) {
    this.router.navigate(['/home']);
  }

  
  async verificarEstabilidadVelocidadConexion(): Promise<void> {
    try {
      // Mostrar mensaje de "CONEXION A INTERNET INESTABLE"
      this.mostrarMensaje('CONEXION A INTERNET INESTABLE', 'red');

      // Esperar 2 segundos
      await this.sleep(2000);

      // Mostrar mensaje de "REESTABLECIENDO CONEXION"
      this.mostrarMensaje('REESTABLECIENDO CONEXION', 'orange');

      // Esperar 2 segundos
      await this.sleep(2000);

      // Mostrar mensaje de "CONEXION A INTERNET REESTABLECIDA"
      this.mostrarMensaje('CONEXION A INTERNET REESTABLECIDA', 'green');

      await this.sleep(2000);

      this.hidden = true;
    } catch (error) {
      console.error('Error al verificar la estabilidad y velocidad de la conexión:', error);
    }
  }

  private mostrarMensaje(mensaje: string, colorFondo: string): void {
    // Actualizar el mensaje y el color de fondo del elemento layout-news
    this.conexionMsj = mensaje;
    this.colorFondoLayoutNews = colorFondo;
  }

  // Función de espera para pausar la ejecución por un tiempo especificado
  private sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }



}
