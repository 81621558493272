import { Injectable } from '@angular/core';
import Quill from 'quill';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuillNotesServies {

    private quillInstance: Quill | null = null;
    private contentChanged = new Subject<string>();

  initializeQuill(selector: string, options: any): Quill {
    this.quillInstance = new Quill(selector, options);

    this.quillInstance.on('text-change', () => {

        const content = this.quillInstance?.root.innerHTML || '';
        this.contentChanged.next(content);
      });


    return this.quillInstance;
  }

  getQuillInstance(): Quill | null {
    return this.quillInstance;
  }

  getContentChanges() {
    return this.contentChanged.asObservable();
  }

  clearContent(): void {
    if (this.quillInstance) {
      const length = this.quillInstance.getLength();
      this.quillInstance.deleteText(0, length);

      // Directly manipulate the DOM to clear the content
      const editor = this.quillInstance.root;
      while (editor.firstChild) {
        editor.removeChild(editor.firstChild);
      }
    }
  }

}