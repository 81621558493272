import { Component , OnInit } from '@angular/core';
import * as $ from 'jquery';

import { faTasksAlt } from '@fortawesome/free-solid-svg-icons';
import { faMailForward } from '@fortawesome/free-solid-svg-icons';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faContactBook } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBills } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-menu-config',
  templateUrl: './menu-config.component.html',
  styleUrl: './menu-config.component.css'
})
export class MenuConfigComponent implements OnInit {

  faTasksAlt = faTasksAlt;
  faMailForward = faMailForward;
  faMailBulk = faMailBulk;
  faContactBook = faContactBook;
  faMoneyBills = faMoneyBills;
  faMagnifyingGlassChart = faMagnifyingGlassChart
  faMapMarker = faMapMarker;
  faInbox = faInbox;
  faMoneyCheckDollar = faMoneyCheckDollar;
  faAngleDown = faAngleDown;
  faWallet = faWallet;


  ngOnInit() {
    var body = $('body');
    var sidebar = $('.sidebar');

    $('.sidebar-toggler').on('click', function (e) {
      e.preventDefault();
      $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
      if (window.matchMedia('(min-width: 992px)').matches) {
        e.preventDefault();
        body.toggleClass('sidebar-folded');
      } else if (window.matchMedia('(max-width: 991px)').matches) {
        e.preventDefault();
        body.toggleClass('sidebar-open');
      }
    });

    var current = location.pathname
      .split('/')
      .slice(-1)[0]
      .replace(/^\/|\/$/g, '');
    $('.nav li a', sidebar).each(function () {
      var $this = $(this);
      addActiveClass($this);
    });

    $('.horizontal-menu .nav li a').each(function () {
      var $this = $(this);
      addActiveClass($this);
    });

    $('.sidebar .sidebar-body').hover(
      function () {
        if (body.hasClass('sidebar-folded')) {
          body.addClass('open-sidebar-folded');
        }
      },
      function () {
        if (body.hasClass('sidebar-folded')) {
          body.removeClass('open-sidebar-folded');
        }
      }
    );

    function addActiveClass(element: any) {
      if (current === '') {
        //for root url
        if (element.attr('href').indexOf('index.html') !== -1) {
          element.parents('.nav-item').last().addClass('active');
          if (element.parents('.sub-menu').length) {
            element.closest('.collapse').addClass('show');
            element.addClass('active');
          }
        }
      } else {
        //for other url
        if (element.attr('href').indexOf(current) !== -1) {
          element.parents('.nav-item').last().addClass('active');
          if (element.parents('.sub-menu').length) {
            element.closest('.collapse').addClass('show');
            element.addClass('active');
          }
          if (element.parents('.submenu-item').length) {
            element.addClass('active');
          }
        }
      }
    }
  }

}