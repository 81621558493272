import { Component } from '@angular/core';

@Component({
  selector: 'app-calificados',
  templateUrl: './calificados.component.html',
  styleUrls: ['./calificados.component.css']
})
export class CalificadosComponent {

}
