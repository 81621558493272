import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WsColmenaInformesService {

  constructor(public http: HttpClient) { }

  async ProspectosporComercial(params: any): Promise<any> {
    const id_empresa = Number(localStorage.getItem('id_empresa'));
    try {
      return await firstValueFrom(
        this.http.get(`${environment.apiUrlV1}informes/ListaContactos`, {
          params: { ...params, id_empresa },
        })
      );
    } catch (error) {
      return this.handleError(error);
    }
  }

  exportExcel(params: { id_empresa: number; usuario: string; tipo: string; fechaInicio: string; fechaFin: string }): Observable<string> {
    const queryParams = new HttpParams()
      .set('id_empresa', String(params.id_empresa))
      .set('usuario', params.usuario)
      .set('tipo', params.tipo)
      .set('fechaInicio', params.fechaInicio)
      .set('fechaFin', params.fechaFin);
    const url = `${environment.apiUrlV1}informes/exportExcel`;
    return this.http.get(url, { params: queryParams, responseType: 'text' });
  }

  private handleError(error: any): Promise<any> {
    console.error('Error en la solicitud HTTP', error);
    return Promise.reject(error);
  }

  async actualizarEstado(id: any, nuevoEstado: string) {
    const id_empresa = Number(localStorage.getItem('id_empresa'));
    try {
      return await firstValueFrom(
        this.http.post(`${environment.apiUrlV1}/actualizar-estado`, {
          id,
          nuevoEstado,
          id_empresa
        })
      );
    } catch (error) {
      return this.handleError(error);
    }
  }
}
