import { Component, AfterViewInit } from '@angular/core';


import * as feather from 'feather-icons';

@Component({
  selector: 'app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.css']
})
export class WidgetsComponent implements AfterViewInit {


  ngAfterViewInit() {
    feather.replace();
  }

}
