// translation-loader.service.ts
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationLoaderService {
  constructor(private translate: TranslateService) {}

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.translate.onLangChange.subscribe(() => {
        resolve();
      });

      // Puedes agregar más idiomas según tus necesidades
      this.translate.addLangs(['en', 'es']);
      this.translate.setDefaultLang('es');

      // Inicialmente, carga los archivos de traducción del idioma predeterminado (en este caso, 'en')
      this.translate.use('es').toPromise();
    });
  }
}
