import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EMPTY_STRING, INTERNAL_PATHS } from '@data/constants/routes';
import { MasterComponent } from '@layout/master/master.component';
import { AuthModule } from '@modules/auth/auth.module';
import { HomeModule } from '@modules/home/home.module';
import { ContactsModule } from '@modules/contacts/contacts.module';
import { SalesModule } from '@modules/sales/sales.module';

import { GoogleCalendarOauthComponent } from '@shared/components/templates/google-calendar-oauth/google-calendar-oauth.component';
import { LoginComponent } from '@modules/auth/pages/login/login.component';
import { MailModule } from '@modules/mail/mail.module';
import { InformesModule } from '@modules/informes/informes.module';
import { GestionComercialModule } from '@modules/gestion-comercial/gestion-comercial.module';
import { ConfigModule } from '@modules/config/config.module';
import { OthersModule } from '@modules/others/others.module';
import { MarketingModule } from '@modules/marketing/marketing.module';
import { GoogleGmailOauthComponent } from '@shared/components/templates';

import { ColmenaAuthComponent } from '@shared/components/templates';

import { DocumentPreviewComponent } from '@shared/components/templates';
import { FormulariosComponent } from './pages/configuraciones/formularios/formularios.component'
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'OAuth2/gogle/callback/calendar',
    component: GoogleCalendarOauthComponent,
  },
  {
    path: 'OAuth2/gogle/callback/mail',
    component: GoogleGmailOauthComponent,
  },
  {
    path: 'OAuth2/colmena/callback/login',
    component: ColmenaAuthComponent,
  },
  {
    path:'documents/view',
    component:DocumentPreviewComponent,
  },
  {
    path: EMPTY_STRING,
    component: MasterComponent,

    children: [
      {
        path: INTERNAL_PATHS.HOME_DEFAULT,
        loadChildren: () =>
          import('@modules/home/home.module').then(
            (m): typeof HomeModule => m.HomeModule
          ),
      },
      {
        path: INTERNAL_PATHS.ASESORI_CONTACT_DEFAULT,
        loadChildren: () =>
          import('@modules/contacts/contacts.module').then(
            (m): typeof ContactsModule => m.ContactsModule
          ),
      },
      {
        path: INTERNAL_PATHS.CRM_SALES_DEFAULT,
        loadChildren: () =>
          import('@modules/sales/sales.module').then(
            (m): typeof SalesModule => m.SalesModule
          ),
      },
      {
        path: INTERNAL_PATHS.EMAIL_DEFAULT,
        loadChildren: () =>
          import('@modules/mail/mail.module').then(
            (m): typeof MailModule => m.MailModule
          ),
      },
      {
        path: INTERNAL_PATHS.AUTH_DEFAULT,
        loadChildren: () =>
          import('@modules/auth/auth.module').then(
            (m): typeof AuthModule => m.AuthModule
          ),
      },
      {
        path: INTERNAL_PATHS.INFORMES_DEFAULT,
        loadChildren: () =>
          import('@modules/informes/informes.module').then(
            (m): typeof InformesModule => m.InformesModule
          ),
      },
      {
        path: INTERNAL_PATHS.GESTION_COMERCIAL_DEFAULT,
        loadChildren: () =>
          import('@modules/gestion-comercial/gestion-comercial.module').then(
            (m): typeof GestionComercialModule => m.GestionComercialModule
          ),
      },
      {
        path: INTERNAL_PATHS.CONFIG_DEFAULT,
        loadChildren: () =>
          import('@modules/config/config.module').then(
            (m): typeof ConfigModule => m.ConfigModule
          ),
      },
      {
        path: INTERNAL_PATHS.CALENDAR_DEFAULT,
        loadChildren: () =>
          import('@modules/others/others.module').then(
            (m): typeof OthersModule => m.OthersModule
          ),
      },
      {
        path: INTERNAL_PATHS.MKT_DEFAULT,
        loadChildren: () =>
          import('@modules/marketing/marketing.module').then(
            (m): typeof MarketingModule => m.MarketingModule
          ),
      },
      {
        path: 'formularios',
        component: FormulariosComponent,
      },
      { path: '**', redirectTo: EMPTY_STRING, pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: EMPTY_STRING, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

/*import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { MasterComponent } from './layouts/master/master.component';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { GoogleCallbackComponent } from './components/google-callback/google-callback.component';
//import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path:'OAuth2/gogle/callback/calendar',
    component:GoogleCallbackComponent
  },
  {
    path: '',
    component: MasterComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/master/master.module').then(m => m.MasterModule)
      }
    ]
  },{
    path: '**',
    redirectTo: ''
  }
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes),
  ],
  exports: [],
})
export class AppRoutingModule {}
*/
