import { AfterViewInit, OnInit, Component } from '@angular/core';
import { ServiceMenuService } from 'src/app/providers/service-menu.service';

import * as feather from 'feather-icons';
import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css'],
})
export class MasterComponent implements OnInit, AfterViewInit {
  title = 'ColmenaCrm';

  constructor(public menuService: ServiceMenuService) {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    feather.replace();
    const sidebarBodyScroll = new PerfectScrollbar('.sidebar-body');
  }
}
