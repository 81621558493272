<div class="container informes-container">
  <!-- SECCIÓN 2: Datos del Formulario -->
  <div class="row mb-4">
    <div class="col-md-12">
      <div class="card">
        <div>
          <h2
            class="h4 text-primary"
            style="
              display: block;
              padding: 15px;
              background: #f6f8fa;
              border-radius: 5px 5px 0px 0px;
              border-bottom: 1px solid #dededf;
            "
          >
            REGISTRO DE COTIZACIÓN
          </h2>
        </div>
        <div class="card-body">
          <form>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="vendedor" class="form-label">N° Cotización</label
                ><input
                  style="cursor: no-drop"
                  id="vendedor"
                  pInputText
                  type="number"
                  formControlName="vendedor"
                  class="form-control"
                  [readOnly]="true"
                  [disabled]="true"
                />
              </div>
              <div class="col-md-4">
                <label for="dateRange" class="form-label"
                  >Fecha de Emisión</label
                ><p-calendar
                  id="dateRange"
                  [style]="{ minWidth: '100%' }"
                  [(ngModel)]="dates"
                  dateFormat="yy-mm-dd"
                  [readonlyInput]="true"
                ></p-calendar>
              </div>
            </div>
            <!-- Información del Cliente -->
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="cliente" class="form-label">Cliente</label
                ><p-dropdown
                  id="cliente"
                  [style]="{ minWidth: '100%' }"
                  optionLabel="name"
                  optionValue="id"
                  [options]="asesores"
                  [filter]="true"
                  filterBy="name"
                  [(ngModel)]="asesorSelectChange"
                  placeholder="Selecciona un cliente"
                ></p-dropdown>
              </div>
              <div class="col-md-4">
                <label for="identificacionCliente" class="form-label"
                  >Identificación</label
                ><input
                  style="cursor: no-drop"
                  id="identificacionCliente"
                  pInputText
                  formControlName="identificacionCliente"
                  class="form-control"
                  [readOnly]="true"
                  [disabled]="true"
                />
              </div>
              <div class="col-md-4">
                <label for="correoCliente" class="form-label"
                  >Correo Electrónico</label
                ><input
                  style="cursor: no-drop"
                  id="correoCliente"
                  pInputText
                  type="email"
                  formControlName="correoCliente"
                  class="form-control"
                  [readOnly]="true"
                  [disabled]="true"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="userSelect" class="form-label">Asesor</label
                ><p-dropdown
                  id="userSelect"
                  [style]="{ minWidth: '100%' }"
                  optionLabel="label"
                  optionValue="value"
                  [options]="asesores"
                  [filter]="true"
                  filterBy="label"
                  [(ngModel)]="asesorSelectChange"
                  placeholder="Seleccione un asesor"
                ></p-dropdown>
              </div>
              <div class="col-md-4">
                <label for="notas" class="form-label">Notas</label
                ><textarea
                  id="notas"
                  pInputTextarea
                  formControlName="notas"
                  rows="3"
                  class="form-control"
                ></textarea>
              </div>
            </div>

            <!-- SECCIÓN 3: Totales -->
            <div class="container informes-container">
              <!-- Header de la Tabla con Búsqueda y Botones -->
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 10px;
                  background-color: #f6f8fa;
                  border-bottom: 1px solid #dededf;
                  border-radius: 5px 5px 0 0;
                "
              >
                <input
                  type="search"
                  placeholder="Buscar código producto, lectura de barra, descripción del producto"
                  style="
                    width: 70%;
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-color: #ccc;
                    border-radius: 4px;
                  "
                />
                <div class="header-buttons" style="display: flex; gap: 10px">
                  <button
                    title="Lista de Items"
                    style="
                      background: none;
                      border-radius: 3px;
                      border-style: solid;
                      border-width: 1px;
                      border-color: #ccc;
                      cursor: pointer;
                      font-size: 1.5rem;
                      
                      transition: color 0.3s;
                    "
                  >
                    <i class="pi pi-list"></i>
                  </button>
                  <button
                    title="Agregar Detalle"
                    style="
                      background: none;
                      border-radius: 3px;
                      border-style: solid;
                      border-width: 1px;
                      border-color: #ccc;
                      cursor: pointer;
                      font-size: 1.5rem;
                      
                      transition: color 0.3s;
                    "
                  >
                    <i class="pi pi-plus"></i>
                  </button>
                </div>
              </div>
              <!-- Contenedor de la Tabla -->
              <div id="productos" #tabulator class="tabulator-table"></div>
            </div>

            <!-- SECCIÓN 4: Totales -->
            <div class="row mb-4">
              <div class="row">
                <div class="col-sm-7">
                  <label for="formaPago" class="form-label">Forma de Pago</label
                  ><input
                    id="formaPago"
                    pInputText
                    formControlName="formaPago"
                    class="form-control"
                  />
                </div>
                <div class="col-sm-5">
                  <div class="form-group row">
                    <label for="subtotalIVA" class="form-label"
                      >Subtotal IVA</label
                    ><input
                      id="subtotalIVA"
                      pInputText
                      formControlName="subtotalIVA"
                      class="form-control"
                      readonly
                    />
                  </div>
                  <div class="form-group row">
                    <label for="subtotal0" class="form-label">Subtotal 0%</label
                    ><input
                      id="subtotal0"
                      pInputText
                      formControlName="subtotal0"
                      class="form-control"
                      readonly
                    />
                  </div>
                  <div class="form-group row">
                    <label for="descuento" class="form-label">Descuento</label
                    ><input
                      id="descuento"
                      pInputText
                      formControlName="descuento"
                      class="form-control"
                      readonly
                    />
                  </div>
                  <div class="form-group row">
                    <label for="iva" class="form-label">IVA</label
                    ><input
                      id="iva"
                      pInputText
                      formControlName="iva"
                      class="form-control"
                      readonly
                    />
                  </div>
                  <div class="form-group row">
                    <label for="otrosImpuestos" class="form-label"
                      >Otros Impuestos</label
                    ><input
                      id="otrosImpuestos"
                      pInputText
                      formControlName="otrosImpuestos"
                      class="form-control"
                      readonly
                    />
                  </div>
                  <div class="form-group row">
                    <label for="total" class="form-label">Total</label
                    ><input
                      id="total"
                      pInputText
                      formControlName="total"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
