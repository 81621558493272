<div class="row">
    <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h6 class="card-title">Widgets</h6>


                <div class="d-flex flex-md-nowrap flex-wrap align-items-center justify-content-between mb-5">
                    <div>
                        <p>Mejora tu experiencia de uso con COLMENA CRM integrando widgets de nuestra lista, los cuales permiten conectar con diversas plataformas</p>
                    </div>
                    <a href="#" class="fs-7 flex-shrink-0"><u></u></a>
                </div>
                <div class="row">
                    <div class="col-xxl-3 col-xl-4 col-md-6">
                        <div class="card card-border card-int mb-4">
                            <div class="card-body">
                                <div class="avatar avatar-sm avatar-logo mb-3">
                                    <span class="initial-wrap">
                                        <img src="../../../../assets/images/widgets/logo-gmail.png" style="width: 30px;" alt="logo">
                                    </span>
                                </div>
                                <div class="app-name">Gmail</div>
                                <div class="app-cat">Correo Electronico</div>
                                <p class="p-sm multine-ellipsis">Conecta con tu bandeja de entrada de correo electrónico y administra tus mensajes.</p>
                            </div>
                            <div class="card-footer justify-content-between border-0">
                                <span class="d-flex align-items-center fs-8"><i
                                        class="ri-download-cloud-2-line fs-7 me-2"></i></span>
                                <a class="btn btn-sm btn-outline-secondary">Conectar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-md-6">
                        <div class="card card-border card-int mb-4">
                            <div class="card-body">
                                <div class="avatar avatar-sm mb-3">
                                    <img src="../../../../assets/images/widgets/brevo.png"  style="width: 30px;"  alt="user" class="avatar-img">
                                </div>
                                <div class="app-name">Brevo</div>
                                <div class="app-cat">Mail Marketing</div>
                                <p class="p-sm multine-ellipsis">Plataforma de email marketing que facilita la administración de tus campañas.</p>
                            </div>
                            <div class="card-footer justify-content-between border-0">
                                <span class="d-flex align-items-center fs-8"><i
                                        class="ri-download-cloud-2-line fs-7 me-2"></i></span>
                                <a class="btn btn-sm btn-outline-secondary">Conectar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-md-6">
                        <div class="card card-border card-int mb-4">
                            <div class="card-body">
                                <div class="avatar avatar-sm mb-3">
                                    <img  src="../../../../assets/images/widgets/zapier.png"  style="width: 30px;"  alt="user" class="avatar-img">
                                </div>
                                <div class="app-name">Zapier</div>
                                <div class="app-cat">Multiples integraciones</div>
                                <p class="p-sm multine-ellipsis">Conecta tus datos con cualquier otra aplicación a través de Zapier.&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;</p>
                            </div>
                            <div class="card-footer justify-content-between border-0">
                                <span class="d-flex align-items-center fs-8"><i
                                        class="ri-download-cloud-2-line fs-7 me-2"></i></span>
                                <a class="btn btn-sm btn-outline-secondary">Conectar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 col-xl-4 col-md-6">
                        <div class="card card-border card-int mb-4">
                            <div class="card-body">
                                <div class="avatar avatar-sm avatar-logo mb-3">
                                    <div class="avatar avatar-sm mb-3">
                                        <img src="../../../../assets/images/widgets/formulario.png"  style="width: 30px;"  alt="logo">
                                    </div>
                                </div>
                                <div class="app-name">Formulario</div>
                                <div class="app-cat">Formularios web</div>
                                <p class="p-sm multine-ellipsis">Integra los formularios de tu sitio web para que los datos se reciban directamente en el CRM.</p>
                            </div>
                            <div class="card-footer justify-content-between border-0">
                                <span class="d-flex align-items-center fs-8"><i
                                        class="ri-download-cloud-2-line fs-7 me-2"></i></span>
                                <a class="btn btn-sm btn-outline-secondary">Conectar</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            


        </div>
    </div>
</div>