<div class="row">
  <div class="col-12">
    <div class="card shadow-sm border-0">
      <div class="card-body">
        <h2 class="card-title h5 text-primary mb-4">Configuración</h2>

        <div class="row">
          <div class="col-md-3">
            <nav
              class="nav flex-column nav-tabs border-end"
              id="config-tabs"
              role="tablist"
            >
              <a
                class="nav-link active"
                id="contact-tab"
                data-bs-toggle="pill"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="true"
                >Formulario Contacto</a
              >
              <a
                class="nav-link"
                id="catalog-tab"
                data-bs-toggle="pill"
                href="#catalog"
                role="tab"
                aria-controls="catalog"
                aria-selected="false"
                >Catálogos</a
              >
            </nav>
          </div>
          <div class="col-md-9">
            <div class="tab-content border p-4" id="config-tabs-content">
              <div
                class="tab-pane fade show active"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div class="d-flex justify-content-end mb-3">
                  <button
                    (click)="openEnd(content)"
                    type="button"
                    class="btn btn-outline-primary d-flex align-items-center"
                  >
                    <i class="mdi mdi-plus-box me-2"></i> Campo Personalizado
                  </button>
                </div>

                <div style="overflow-x: auto">
                  <div>
                    <div #tabulator id="tabulator"></div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="catalog"
                role="tabpanel"
                aria-labelledby="catalog-tab"
              >
                <h3 class="h6">Profile</h3>
                <p>
                  Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim
                  irure officia enim reprehenderit...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Campo Personalizado</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="offcanvas.dismiss('Cross click')"
    ></button>
  </div>
  <div class="offcanvas-body">
    <div class="alert alert-primary d-flex align-items-center" role="alert">
      <i class="mdi mdi-alert-circle-outline me-2"></i>
      <div>
        Por favor, selecciona el tipo de campo a personalizar para mostrar el
        formulario de registro.
      </div>
    </div>

    <form [formGroup]="customFieldForm" (ngSubmit)="addCustomField()">
      <!-- Tipo de Campo -->
      <div class="py-1">
        <div>
          <div class="pb-1 form_label_wrapper">
            <label for="tipoCampo" class="form-label">
              <span>Tipo de Campo</span>
            </label>
          </div>
          <p-dropdown
            [style]="{ width: '100%' }"
            [options]="fieldTypes"
            formControlName="tipoCampo"
            id="tipoCampo"
            placeholder="Seleccione un tipo"
          ></p-dropdown>
        </div>
      </div>

      <!-- Etiqueta -->
      <div class="mb-3">
        <label for="inputEtiqueta" class="form-label"
          >Etiqueta <span class="text-danger">*</span></label
        >
        <small id="inputEtiquetaHelp" class="form-text text-muted">
          Ingresa el nombre de cómo quieres que se visualice para el usuario.
        </small>
        <input
          type="text"
          class="form-control"
          id="inputEtiqueta"
          formControlName="etiqueta"
          placeholder="Etiqueta"
          aria-describedby="inputEtiquetaHelp"
          required
        />
      </div>

      <!-- Nombre Interno -->
      <div class="mb-3">
        <label for="inputNombreInterno" class="form-label"
          >Nombre Interno <span class="text-danger">*</span></label
        >
        <small id="inputNombreInternoHelp" class="form-text text-muted">
          Define un nombre que será utilizado como código identificador del
          nuevo campo.
        </small>
        <input
          type="text"
          class="form-control"
          id="inputNombreInterno"
          formControlName="nombreInterno"
          placeholder="Nombre Interno"
          aria-describedby="inputNombreInternoHelp"
          required
        />
      </div>

      <!-- Campo Obligatorio -->
      <div class="form-check form-switch">
        <input
          type="checkbox"
          class="form-check-input"
          id="campoObligatorio"
          formControlName="campoObligatorio"
        />
        <label class="form-check-label" for="campoObligatorio"
          >Campo Obligatorio</label
        >
      </div>

      <!-- Botón de Submit -->
      <button type="submit" class="btn btn-primary mt-3">Agregar Campo</button>
    </form>
  </div>
</ng-template>
