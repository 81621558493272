import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
//import { SidebarModule } from 'primeng/sidebar';
import { CarouselModule } from 'primeng/carousel';  // Asegúrate de tener PrimeNG Carousel importado
import { ChartModule } from 'primeng/chart';          // Y también el módulo para gráficos
import { SkeletonModule } from 'primeng/skeleton';  


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    CarouselModule,
    ChartModule,
    SkeletonModule
    //SidebarModule
  ],
  
  declarations: [
    //GridDocumentsComponent,
    //GridUserComponent,
    //NotAvailableComponent,
    //LoadGridComponent,
    //EmptyGridComponent,
    //GridContactoComponent,
    ///GridConfigContactoComponent
  ],
  exports: [
  ]
})
export class ComponentsModule { }
