import { Component } from '@angular/core';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mod-cotizador-footer',
  templateUrl: './mod-cotizador-footer.component.html',
  styleUrl: './mod-cotizador-footer.component.css'
})
export class ModCotizadorFooterComponent {


  faArrowRightArrowLeft = faArrowRightArrowLeft;
  faCaretLeft = faCaretLeft;
  faCircleArrowRight = faCircleArrowRight;

}
