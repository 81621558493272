<div id="main-wrapper" class="oxyy-login-register">
    <div class="container-fluid px-0">
        <div class="row g-0 min-vh-100">

            <!-- Welcome Text
      ========================= -->
            <div class="col-md-7 col-lg-8 bg-light">
                <div class="hero-wrap d-flex align-items-center h-100">
                    <div class="hero-mask opacity-8"></div>
                    <div class="content-public hero-bg hero-bg-scroll">
                    </div>
                    <div class="hero-content mx-auto w-100 h-100 d-flex flex-column">
                        <div class="container">
                            <div class="row g-0 mt-5">
                                <div class="col-11 col-md-10 col-lg-9 mx-auto">
                                    <h1 class="text-13 text-white fw-600 mb-4"></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Welcome Text End -->

            <!-- Login Form
      ========================= -->
            <div class="col-md-5 col-lg-4 d-flex flex-column align-items-center">
                <div class="container pt-4">
                    <!--div class="row g-0">
                        <div class="col-11 col-md-10 col-lg-9 mx-auto">
                            <div class="logo"> <a
                                    class="fw-600 text-6 text-dark link-underline link-underline-opacity-0"
                                    href="index.html" title="Oxyy">Oxyy</a> </div>
                        </div>
                    </div-->
                </div>
                <div class="container my-auto py-5">
                    <div class="row g-0">
                        <div class="col-11 col-md-10 col-lg-9 mx-auto">
                            <h3 class="text-12 mb-4">Inicie sesión para acceder a su cuenta Clientyx</h3>
                            <form [formGroup]="loginForm" (ngSubmit)="doLogin()" novalidate >
                                <label class="form-label fw-500" for="emailAddress">Usuario</label>
                                <div class="mb-3 icon-group icon-group-end">
                                    <input  formControlName="usuario" clearInput type="text" pInputText class="form-control bg-light border-light" id="emailAddress"
                                        required placeholder="identificacion o correo">
                                    <span class="icon-inside text-muted"><i class="fas fa-envelope"></i></span>
                                </div>
                                <label class="form-label fw-500" for="loginPassword">Password</label>
                                <div class="mb-3 icon-group icon-group-end">
                                    <input formControlName="password" type="password" pInputText class="form-control form-control-lg bg-light border-light"
                                        id="loginPassword" required placeholder="Password">
                                    <span class="icon-inside text-muted"><i class="fas fa-lock"></i></span>
                                </div>
                                <!--div class="row my-4">
                                    <div class="col">
                                        <div class="form-check">
                                            <input id="remember-me" name="remember" class="form-check-input"
                                                type="checkbox">
                                            <label class="form-check-label" for="remember-me">Remember Me</label>
                                        </div>
                                    </div>
                                    <div class="col text-end"><a href="forgot-password-18.html">Forgot Password
                                            ?</a></div>
                                </div-->
                                <div class="d-grid my-4">
                                    <button class="btn btn-dark btn-lg" [loading]="loading" type="submit" pButton pRipple>Validar</button>
                                </div>
                                <!--p class="text-2 text-muted text-center">Not a member? <a href="register-18.html">Sign
                                        Up now</a></p-->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Login Form End -->

        </div>
    </div>
</div>




<!--div class="content-login" dir="ltr">

    <div [hidden]="hidden" class="layout-news" *ngIf="conexionInestable"
        [style.background-color]="colorFondoLayoutNews">
        <div class="layout-news-container">
            <i class="pi pi-wifi " style="color: white"></i>
            <div class="layout-news-content">
                <span class="layout-news-text">{{conexionMsj}}</span>
            </div>
            <a tabindex="0" class="layout-news-close"><span class="pi pi-times"></span></a>
        </div>
    </div>


    <div class="layout-login">

        <div class="layout-login__overlay">
            <img src="../../../../../assets/images/banner_login.jpg">
        </div>
        <div class="layout-login__form bg-white" data-perfect-scrollbar>
            <div class="d-flex justify-content-center mt-2 mb-5 navbar-light">
                <img class="navbar-brand-icon" src="../../../../../assets/images/LOGO-CLIENTEYX.png" width="300"
                    alt="Stack">
            </div>
            <h4 class="m-0">Bienvenido</h4>
            <p class="mb-5">Inicie sesión para acceder a su cuenta Clientyx </p>
            <form [formGroup]="loginForm" (ngSubmit)="doLogin()" novalidate>
                <div class="form-group">
                    <label class="text-label" for="email_2">Usuario:</label>
                    <div class="input-group input-group-merge">
                        <input formControlName="usuario" clearInput type="text" pInputText
                            class="form-control form-control-prepended" placeholder="identificacion o correo">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <fa-icon class="color-spinner" [icon]="faUserSecret"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="text-label" for="password_2">Contraseña:</label>
                    <div class="input-group input-group-merge">
                        <input formControlName="password" type="password" pInputText
                            class="form-control form-control-prepended" placeholder="***************">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <fa-icon class="color-spinner" [icon]="faKey"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-5">
       
                </div>
                <div class="form-group text-center">
                    <button [loading]="loading" type="submit" pButton pRipple
                        class="btn btn-primary mb-5">Ingresar</button><br>
                </div>
            </form>
        </div>
        <p-toast position="top-center" [showTransformOptions]="'translateX(100%)'" [showTransitionOptions]="'1000ms'"
    [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" />
    </div>
    <div>
        
    </div>
</div-->