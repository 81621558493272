import {AfterViewInit,OnInit, Component } from '@angular/core';

import * as feather from 'feather-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements
  OnInit, AfterViewInit {
  title = 'ColmenaCrm';


  constructor() { }

  ngOnInit(): void {
    feather.replace();
  }
  ngAfterViewInit() {

  
    feather.replace();
  }

}
