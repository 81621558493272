import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges,
} from '@angular/core';
import * as feather from 'feather-icons';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import Swal, { SweetAlertIcon } from 'sweetalert2';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { WsColmenaGeneralService } from 'src/app/providers/ws-colmena-general.service';

@Component({
  selector: 'app-sidebar-agenda',
  templateUrl: './sidebar-agenda.component.html',
  styleUrl: './sidebar-agenda.component.css',
})
export class SidebarAgendaComponent implements OnChanges {
  @Input() NgModelAsistente: any;
  @Input() ValuesAsistente: any;
  @Input() visible: boolean = false;
  @Input() IsLoadPages: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input() FechaInicio_I: Date = new Date();
  @Input() FechaFin_I: Date = new Date();
  @Input() HoraInicio: string = '';
  @Input() HoraFin: string = '';

  faSpinner = faSpinner;
  faCircleNotch = faCircleNotch;

  public descripcion_agenda: string = '';
  public titulo_agenda: string = '';
  public FechaRegisterCalendar: Date = new Date();
  public FechaInicio: Date = new Date();
  public FechaFin: Date = new Date();
  public HoraRegisterInit: Date = new Date();
  public LinkConferencia: boolean = false;
  public HoraRegisterFinish: Date = new Date();
  public AsistenteValueSelect: string[] = [];
  public form: FormGroup;

  public asistentesControl: FormControl = new FormControl();

  procesando: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public ServicioGeneral: WsColmenaGeneralService,
    private datePipe: DatePipe,
    private elementRef: ElementRef
  ) {
    this.form = this.formBuilder.group({});
  }

  ngOnChanges(changes: any) {
    if (changes.NgModelAsistente && !changes.NgModelAsistente.firstChange) {
      this.form.patchValue({ AsistenteValueSelect: this.ValuesAsistente });
      //this.disableSelectedOptions();
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      titulo: ['', Validators.required],
      FechaRegisterCalendar: [new Date(), Validators.required],
      HoraRegisterInit: [new Date(), Validators.required],
      HoraRegisterFinish: [new Date(), Validators.required],
      AsistenteValueSelect: [[], Validators.required],
      descripcion_agenda: ['', Validators.required],
      LinkConferencia: [false],
    });

    this.setFocus('titulo');
  }

  /*
  disableOption(value: string) {
    const index = this.NgModelAsistente.findIndex((item: any) => item.value === value);
    if (index !== -1) {
      this.NgModelAsistente[index].disabled = true;
    }
  }*/

  isDisabled(value: string): boolean {
    // Coloca aquí la lógica para determinar si un elemento debe estar deshabilitado
    // Por ejemplo, si el valor está en ValuesAsistente
    return this.ValuesAsistente.some(
      (item: { value: string }) => item.value === value
    );
  }

  disableSelectedOptions() {
    if (this.ValuesAsistente && this.ValuesAsistente.length > 0) {
      this.NgModelAsistente.forEach((group: any) => {
        group.items.forEach((item: any) => {
          //if (this.ValuesAsistente.includes(item.code)) {
          item.disabled = true;
          //}
        });
      });
    }
  }

  GenerarRegistroCalendario() {
    let FormSave = this.form.value;
    let anfitriones: string[] = [];

    this.FechaInicio = new Date(FormSave.FechaRegisterCalendar.getTime());
    this.FechaFin = new Date(FormSave.FechaRegisterCalendar.getTime());

    this.FechaInicio.setHours(FormSave.HoraRegisterInit.getHours());
    this.FechaInicio.setMinutes(FormSave.HoraRegisterInit.getMinutes());

    this.FechaFin.setHours(FormSave.HoraRegisterFinish.getHours());
    this.FechaFin.setMinutes(FormSave.HoraRegisterFinish.getMinutes());

    anfitriones.push(FormSave.AsistenteValueSelect[0].code);
    anfitriones.push(FormSave.AsistenteValueSelect[1].code);

    let eventCalendario = {
      descripcion_evento: FormSave.descripcion_agenda,
      titulo: FormSave.titulo,
      fecha_inicio: this.datePipe.transform(
        this.FechaInicio,
        'dd/MM/yyyy HH:mm:ss'
      ),
      hora_inicio: '',
      fecha_fin: this.datePipe.transform(this.FechaFin, 'dd/MM/yyyy HH:mm:ss'),
      hora_fin: '',
      anfitriones: anfitriones,
      linkMeet: FormSave.LinkConferencia,
    };

    if (this.form.valid) {
      this.procesando = true; // Activa el estado de "procesando"
      this.form.disable(); // Bloquea el formulario

      this.ServicioGeneral.RegistrarEventoCalendario(
        eventCalendario,
        Number(localStorage.getItem('id_empresa')),
        Number(localStorage.getItem('id_usuario'))
      )
        .then((response: any) => {
          this.form.enable();
          this.procesando = false;

          if (response && response.cod === 7) {
            this.alertSweet('success', 'Registro exitoso', '');
            this.form.reset(); // Habilita el formulario nuevamente
            this.toggleSidebarAgenda();
          } else {
            this.alertSweet('info', 'Registro no se genero', '');
            return;
          }
        })
        .catch((ex: any) => {
          console.log(ex);

          this.form.enable();
          this.procesando = false;
        });
    } else {
      this.form.enable(); // Habilita el formulario nuevamente
      this.procesando = false;

      console.log('El formulario no es válido');
    }
  }

  toggleSidebarAgenda() {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }

  onHideSidebar() {
    this.toggleSidebarAgenda();
  }

  renderizarIconos() {
    setTimeout(() => {
      const elementosIcono =
        this.elementRef.nativeElement.querySelectorAll('.icon_button'); // Reemplaza '.feather-icon' por el selector de tus iconos
      elementosIcono.forEach((icono: any) => {
        feather.replace({
          // Reemplaza feather.replace con el método que utilizas para renderizar tus iconos Feather
          target: icono,
        });
      });
    }, 0);
  }

  setFocus(controlName: string) {
    const control = this.form.get(controlName);
    if (control) {
      const element = this.elementRef.nativeElement.querySelector(
        `[formcontrolname='${controlName}']`
      );
      if (element) {
        element.focus();
      }
    }
  }

  async alertSweet(tipo: SweetAlertIcon, titulo: string, texto: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: tipo,
      title: titulo,
      text: texto,
      //,footer:"Si lo necesita llame a soporte Colmena??"
    });
  }
}
