<div id="main-wrapper" class="oxyy-login-register">
    <div class="container-fluid px-0">
        <div class="row g-0 min-vh-100">
            <!-- Welcome Text -->
            <div class="col-md-7 col-lg-8 bg-light">
                <div class="hero-wrap d-flex align-items-center h-100">
                    <div class="hero-mask opacity-8"></div>
                    <div class="content-public hero-bg hero-bg-scroll"></div>
                    <div class="hero-content mx-auto w-100 h-100 d-flex flex-column">
                        <div class="container">
                            <div class="row g-0 mt-5">
                                <div class="col-11 col-md-10 col-lg-9 mx-auto">
                                    <h1 class="text-13 text-white fw-600 mb-4">Bienvenido a Clientyx CRM</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Login Form -->
            <div class="col-md-5 col-lg-4 d-flex flex-column align-items-center">
                <div class="container my-auto py-5">
                    <div class="row g-0">
                        <div class="col-11 col-md-10 col-lg-9 mx-auto">
                            <h3 class="text-12 mb-4">Acceda a su cuenta Clientyx</h3>
                            <form [formGroup]="loginForm" (ngSubmit)="doLogin()" novalidate>
                                <label class="form-label fw-500" for="emailAddress">Usuario</label>
                                <div class="mb-3 icon-group icon-group-end">
                                    <input formControlName="usuario" type="text"
                                        class="form-control bg-light border-light rounded" id="emailAddress" required
                                        aria-label="Ingrese su identificación o correo"
                                        placeholder="Identificación o correo" />

                                    <div *ngIf="loginForm.get('usuario')?.invalid && loginForm.get('usuario')?.touched"
                                        class="text-danger small">
                                        Debe ingresar usuario.
                                    </div>
                                </div>
                                <label class="form-label fw-500" for="loginPassword">Contraseña</label>
                                <div class="mb-3 icon-group icon-group-end">
                                    <input formControlName="password" type="password"
                                        class="form-control form-control-lg bg-light border-light rounded"
                                        id="loginPassword" required aria-label="Ingrese su contraseña"
                                        placeholder="Contraseña" />
                                    <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched"
                                        class="text-danger small">
                                        Debe ingresar la clave.
                                    </div>
                                </div>
                                <div class="d-grid my-4">
                                    <button class="btn btn-dark btn-lg" type="submit" [disabled]="loading">
                                        <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
                                        <span *ngIf="!loading">Ingresar</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Login Form End -->
        </div>
    </div>
</div>