<div class=" content-grid-empty private-flex__child  content-flex-grid-empty">
    <div class="p-top-25 height-100">
        <div class="overflow-auto height-100">
            <div class="p-bottom-5">
                <div class="UISplitLayout__Outer-ugitzj-0 grid-emtpty-info">
                    <div direction="row" wrap="wrap"
                        class="grid-emtpty-private flex  grid-emtpty-private-margins">
                        <div orientation="horizontal"
                            class="grid-image-empty grid-image-empty-margins">
                            <fa-icon class="color-spinner" [icon]="faCircleNotch" size="4x" animation="spin" ></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>