<div class="content-widget-process">

  <section
    class="template-section template-section-spaced-vertical">
    <div class="next-spaced-vertil">
      <div class="next-spaced">
        <main class="main main-flush">
          <div>
            <div>
              <div>
                <div
                  class="alert alert_element alert-info alert-info" role="alert">
                  <div class="alert_inner">
                    <h5 class="jNwKmT private-alert__title">
                      En este momento estamos verificando los permisos de tu usuario para acceder a Clientyx.
                    </h5>
                    <div class="alert_body vertical_spacing">
                      <div>
                        <div
                          class="UIFlex__StyledFlex-sc-1vtdnkq-0 cEsWi flex UIButtonWrapper__FlexContainer-dvknq6-0 dCSvfm">
                                <span>
                                    Permítenos un momento. Una vez finalizado el registro, te redireccionaremos. Por favor, no cambies ni cierres la pantalla.
                                </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="element-present">
              <div class="row  row-content-text">
                <div class="col col-sm-12 col-md-6">
                  <div
                    class="content-text-element flex"
                    style="max-width: 370px;">
                    <h1 class="mb-3 dMNEwg">
                      Clientyx + Colmena
                    </h1>
                    La sincronización segura entre el sistema Colmena y Clientyx está en proceso. Este paso garantiza que tu usuario esté autenticado correctamente para permitir una integración fluida entre ambas plataformas. Durante esta autenticación, tus credenciales son protegidas mediante métodos de seguridad avanzados, asegurando que tus datos permanezcan confidenciales y protegidos en todo momento. Una vez completado, podrás disfrutar de una experiencia optimizada y sin interrupciones. Por favor, no cierres esta ventana mientras finalizamos el proceso.
                  </div>
                  <div>
                    <p-progressBar mode="indeterminate" styleClass="load-widgets" [style]="{ width:'380px', height: '6px', margintop: '50px' }"></p-progressBar>
                  </div>
                </div>
                <div class="col col-sm-12 col-md-6">
                  <div><img alt="" class="image image-responsive"
                            src="../../../../../assets/images/clientyx-colmena-logo.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </section>

</div>
