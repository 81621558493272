import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormsService {
  private apiUrl = environment.apiUrlV1;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  /**
   * Obtiene los campos de formulario personalizados
   * @param idEmpresa ID de la empresa
   * @param ruc RUC de la empresa
   * @returns Observable con la respuesta del servidor
   */
  getCamposFormulario(idEmpresa: number, ruc: string): Observable<any> {
    const params = new HttpParams()
      .set('id_empresa', idEmpresa.toString())
      .set('ruc', ruc);

    return this.http.get(`${this.apiUrl}/contactos/campos_formulario`, { 
      headers: this.headers, 
      params 
    }).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Actualiza los campos del formulario personalizado
   * @param campos Lista de campos a actualizar
   * @param idEmpresa ID de la empresa
   * @param ruc RUC de la empresa
   * @returns Observable con la respuesta del servidor
   */
  actualizarCamposFormulario(campos: any[], idEmpresa: number, ruc: string): Observable<any> {
    const params = new HttpParams()
      .set('id_empresa', idEmpresa.toString())
      .set('ruc', ruc);

    return this.http.post(`${this.apiUrl}/contactos/actualizar_campos_formulario`, 
      campos, 
      { headers: this.headers, params }
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Maneja los errores de las peticiones HTTP
   * @param error Error de la petición
   * @returns Observable con el error
   */
  private handleError(error: any) {
    console.error('Error en la petición:', error);
    let errorMessage = 'Ocurrió un error en el servidor';
    
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.status) {
      // Error del lado del servidor
      errorMessage = `Error ${error.status}: ${error.error?.mensaje || error.statusText}`;
    }
    
    return throwError(() => new Error(errorMessage));
  }
} 