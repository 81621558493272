export const ROUTES_PATHS = {
	AUTH: {
		DEFAULT: `login`,
		AUTH: `auth`,
		LOGIN: `sigin`,
		REGISTER: `sigup`,
	},
	CRM: {
		DEFAULT: ``,
		CONTACT: {
			DEFAULT: `prospectos`,
			RESULTS: `results`,
		},
		HOME: {
			DEFAULT: `home`
		},
		EMAIL: {
			DEFAULT: `inbox`
		},
		INFORMES: {
			DEFAULT: ``,
			REPORTS: `reports`,
			PANELES: `paneles`,
			METAS: `metas`
		},
		CALENDAR: {
			DEFAULT: ``,
			FULLCALENDAR: `calendar`
		},
		SALES: {
			DEFAULT: ``,
			PRODUCTS: `productos`,
			DOCUMENTS: `documents`,
			COTIZA: `cotizador`,
			FACTURA: `factura`,
		},
		CREDIT: {
			DEFAULT: `credit`,
			RESULTS: `results`,
		},
		INSURANCE: {
			DEFAULT: `insurance`,
			RESULTS: `results`,
		},
		CONFIG: {
			DEFAULT: ``,
			GENERAL: `general`,
			CONTACTO: `config_contacto`,
			TEAMS: `config_teams`,
			WIDGETS: `config_widgets`,
		},
		MKT: {
			DEFAULT: `campanias`
		}
	},
	SERVER: {
		ERROR_404: `404`,
		ERROR_400: `400`,
		ERROR_401: `401`,
		ERROR_403: `403`,
		ERROR_500: `500`,
	},
};

export const INTERNAL_PATHS = {
	/**
	 * Auth
	 */
	AUTH_DEFAULT: `${ROUTES_PATHS.AUTH.DEFAULT}`,
	AUTH_LOGIN: `${ROUTES_PATHS.AUTH.LOGIN}`,
	AUTH_REGISTER: `${ROUTES_PATHS.AUTH.REGISTER}`,
	/**
	 * ASESORI
	 */
	HOME_DEFAULT: `${ROUTES_PATHS.CRM.HOME.DEFAULT}`,

	/**
	 * ASESORI
	 */
	ASESORI_DEFAULT: `${ROUTES_PATHS.CRM.DEFAULT}`,


	/**
	 * EMAIL
	 */
	EMAIL_DEFAULT: `${ROUTES_PATHS.CRM.EMAIL.DEFAULT}`,

	/**
	 * INFORMES
	 */
	INFORMES_DEFAULT: `${ROUTES_PATHS.CRM.INFORMES.DEFAULT}`,

	/**
	 * CALENDAR
	 */
	CALENDAR_DEFAULT: `${ROUTES_PATHS.CRM.CALENDAR.DEFAULT}`,

	/**
	 * CALENDAR
	 */
	MKT_DEFAULT: `${ROUTES_PATHS.CRM.MKT.DEFAULT}`,

	/**
	 * CONTACTOS - PROSPECTO
	 */
	ASESORI_CONTACT_DEFAULT: `${ROUTES_PATHS.CRM.CONTACT.DEFAULT}`,
	//ASESORI_CREDIT_RESULTS: `${ROUTES_PATHS.ASESORI.CREDIT.RESULTS}`,

	/**
	 * VENTAS
	 */
	CRM_SALES_DEFAULT: `${ROUTES_PATHS.CRM.SALES.DEFAULT}`,
	CRM_SALES_PRODUCT: `${ROUTES_PATHS.CRM.SALES.PRODUCTS}`,
	CRM_SALES_DOCUMENTS: `${ROUTES_PATHS.CRM.SALES.DOCUMENTS}`,
	CRM_SALES_COTI: `${ROUTES_PATHS.CRM.SALES.COTIZA}`,
	CRM_SALES_FACT: `${ROUTES_PATHS.CRM.SALES.FACTURA}`,

	/**
	 * CONFIGURACIOONES
	 */
	CONFIG_DEFAULT: `${ROUTES_PATHS.CRM.CONFIG.DEFAULT}`,
	CONFIG_GENERAL: `${ROUTES_PATHS.CRM.CONFIG.GENERAL}`,
	CONFIG_CONTACTO: `${ROUTES_PATHS.CRM.CONFIG.CONTACTO}`,
	CONFIG_TEAMS: `${ROUTES_PATHS.CRM.CONFIG.TEAMS}`,
	CONFIG_WIDGETS: `${ROUTES_PATHS.CRM.CONFIG.WIDGETS}`,
	
	
	//ASESORI_CREDIT_RESULTS: `${ROUTES_PATHS.ASESORI.CREDIT.RESULTS}`,

	/**
	 * ASESORI - CREDIT
	 */
	ASESORI_CREDIT_DEFAULT: `${ROUTES_PATHS.CRM.CREDIT.DEFAULT}`,
	ASESORI_CREDIT_RESULTS: `${ROUTES_PATHS.CRM.CREDIT.RESULTS}`,
	/**
	 * ASESORI - INSURANCE
	 */
	ASESORI_INSURANCE_DEFAULT: `${ROUTES_PATHS.CRM.INSURANCE.DEFAULT}`,
	ASESORI_INSURANCE_RESULTS: `${ROUTES_PATHS.CRM.INSURANCE.RESULTS}`,
	/**
	 * Server
	 */
	SERVER_ERROR_404: `${ROUTES_PATHS.SERVER.ERROR_404}`,
	SERVER_ERROR_401: `${ROUTES_PATHS.SERVER.ERROR_401}`,
	SERVER_ERROR_400: `${ROUTES_PATHS.SERVER.ERROR_400}`,
	SERVER_ERROR_403: `${ROUTES_PATHS.SERVER.ERROR_403}`,
	SERVER_ERROR_500: `${ROUTES_PATHS.SERVER.ERROR_500}`,
};

export const INTERNAL_ROUTES = {
	/**
	 * Auth
	 */
	AUTH_LOGIN: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_LOGIN}`,
	AUTH_REGISTER: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_REGISTER}`,
	/**
	 * Server
	 */
	SERVER_ERROR_404: `/${INTERNAL_PATHS.SERVER_ERROR_404}`,
	SERVER_ERROR_401: `/${INTERNAL_PATHS.SERVER_ERROR_401}`,
	SERVER_ERROR_400: `/${INTERNAL_PATHS.SERVER_ERROR_400}`,
	SERVER_ERROR_403: `/${INTERNAL_PATHS.SERVER_ERROR_403}`,
	SERVER_ERROR_500: `/${INTERNAL_PATHS.SERVER_ERROR_500}`,
	/**
	 * ASESORI
	 */
	ASESORI_DEFAULT: `/${INTERNAL_PATHS.ASESORI_DEFAULT}`,
	/**
	 * ASESORI - CREDIT
	 */
	ASESORI_CREDIT_DEFAULT: `/${INTERNAL_PATHS.ASESORI_CREDIT_DEFAULT}`,
	ASESORI_CREDIT_RESULTS: `/${INTERNAL_PATHS.ASESORI_CREDIT_DEFAULT}/${INTERNAL_PATHS.ASESORI_CREDIT_RESULTS}`,
	/**
	 * ASESORI - INSURANCE
	 */
	ASESORI_INSURANCE_DEFAULT: `/${INTERNAL_PATHS.ASESORI_INSURANCE_DEFAULT}`,
	ASESORI_INSURANCE_RESULTS: `/${INTERNAL_PATHS.ASESORI_INSURANCE_DEFAULT}/${INTERNAL_PATHS.ASESORI_INSURANCE_RESULTS}`,
};