<div class="main-wrapper">

    <div *ngIf="menuService.getCurrentMenu() === 'menuPrincipal'">
        <app-menu></app-menu>
    </div>
    <div *ngIf="menuService.getCurrentMenu() === 'menuConfiguracion'">
        <app-menu-config></app-menu-config>
    </div>
    <div class="page-wrapper">
        <app-header></app-header>
        <div class="page-content">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>