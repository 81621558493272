<div class=" content-grid-empty private-flex__child  content-flex-grid-empty">
    <div class="p-top-5 height-100">
        <div class="overflow-auto height-100">
            <div class="p-bottom-2">
                <div class="UISplitLayout__Outer-ugitzj-0 hlMLQV grid-emtpty-info">
                    <div direction="row" wrap="wrap"
                        class="grid-emtpty-private flex  grid-emtpty-private-margins">
                        <div orientation="horizontal" order="0"
                            class="grid-text-empty  grid-text-empty-margins">
                            <h4 aria-level="2">
                                <span>No hay
                                    información registrada que coincida con los filtros de búsqueda aplicados. Por
                                    favor, verifique los criterios de búsqueda.</span>
                            </h4>
                            <span>En caso de estar seguro de que existe información con los filtros aplicados, por favor, refresque la pantalla.</span>
                        </div>
                        <div orientation="horizontal"
                            class="grid-image-empty grid-image-empty-margins">
                            <img alt="" class="private-image-filter-empty"
                                src="../../../assets/images/searchcrm_colmena.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>