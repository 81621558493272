import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  nombres: string = '';
  correo: string = '';

  constructor(public translate: TranslateService, private router: Router) {
    // translate.addLangs(['es', 'en']);
    //translate.setDefaultLang('en');
  }

  ngAfterViewInit() {
    var nom = JSON.parse(localStorage.getItem('sessionData') ?? '');
    var emp = JSON.parse(nom.dato1).empresa[0];

    console.log(emp.correo);

    this.nombres = nom.desc;
    this.correo = emp.correo
  }

  DoLogin() {
    localStorage.clear();
    this.router.navigate(['/']);
  }


  getInitials(fullName: string): string {
    if (!fullName) return '';

    const nameParts = fullName.split(' ').filter(part => part.trim() !== '');
    
    if (nameParts.length === 0) return '';

    let initials = '';

    if (nameParts.length >= 2) {
      // Si hay 2 o más partes, tomar la primera y la segunda
      initials = nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
    } 
    else {
      // Si solo hay una parte, tomar la inicial de esa parte
      initials = nameParts[0].charAt(0).toUpperCase();
    }

    if (nameParts.length >= 4) {
      // Si hay 4 o más partes, tomar la primera y la tercera
      initials = nameParts[0].charAt(0).toUpperCase() + nameParts[2].charAt(0).toUpperCase();
    }

    return initials;
  }

}