import { Component } from '@angular/core';
import {ProgressBarModule} from "primeng/progressbar";
import {ActivatedRoute} from "@angular/router";
import {AuthColmenaService} from "../../../../providers/auth-colmena.service";


@Component({
  selector: 'app-colmena-auth',
  templateUrl: './colmena-auth.component.html',
  styleUrl: './colmena-auth.component.css'
})
export class ColmenaAuthComponent {


  constructor(private route: ActivatedRoute, private authService: AuthColmenaService) {}

  ngOnInit(): void {
    // Obtener los parámetros de la URL
    this.route.queryParams.subscribe(params => {
      const urlParams = new URLSearchParams(params);
      this.authService.loginFromUrl(urlParams.toString());
    });
  }

}
