<p-dialog styleClass="ModalNoBluer" (onShow)="onDialogShow()" [(visible)]="visible" (onHide)="onCloseDialog()" position="bottomright"
    [style]="{ width: '680px' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [maximizable]="true">

    <div>
        <div class="header">
            <ng-template pTemplate="header">
                Nuevo correo
            </ng-template>
            <ng-template pTemplate="closeicon">
                x
            </ng-template>
        </div>
        <div class="subheader">
            sdfsdfdsfsdsdf
        </div>
    </div>

    <ng-template pTemplate="footer">
        <!-- Contenedor para la barra de herramientas -->
        <div id="QuillToolbarContainer" class="ToolbarMailQuill">
            <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>                
                <button class="ql-image"></button>
                <button class="ql-align"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
            </span>
        </div>
        <div class="modalNotesFotterButtonMail flex">
            <button
                class="button_static button-primary button-default disabled private-button--disabled private-button--non-link"
                (click)="addNotes()"
                [ngClass]="{'button-disabled': !NoteComponentRegister}" [disabled]="!NoteComponentRegister"
                type="button">
                <span *ngIf="!procesando">Enviar</span>
                <!--span *ngIf="procesando">Procesando </span>
                <span *ngIf="procesando" class="fa fa-spinner fa-spin"></span-->
                <fa-icon *ngIf="procesando" class="color-spinner" [icon]="faCircleNotch" size="1x" animation="spin" ></fa-icon>
            </button>
            <div  *ngIf="LoadVisibleSaves" >
                <div class="UIFlex__StyledFlex-sc-19v8i33-0 jxJTcj flex">
                    <div *ngIf="guardando">
                        <!-- Aquí colocas tu indicador de carga, por ejemplo un spinner -->
                        <p-progressSpinner 
                            styleClass="w-1rem h-1rem" 
                            strokeWidth="3" 
                            fill="var(--surface-ground)"
                            animationDuration=".5s" />
                        Guardando
                    </div>
                    <p-confirmPopup/>
                    <div *ngIf="!guardando" class="element-success">
                        
                        Guardado
                        <button (click)="deleteTempNotes()"
                            class="button-info-non button-unstyled " type="button">
                            <span class="graper-button">
                                <i class="icon_button" data-feather="trash-2"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="content">
        <div id="QuillEditoNote" class="QuillEditorContentMail" [(ngModel)]="NoteComponentRegister"></div>
    </ng-template>



</p-dialog>

<p-toast 
    position="top-center"
    [showTransformOptions]="'translateX(100%)'" 
    [showTransitionOptions]="'1000ms'" 
    [hideTransitionOptions]="'1000ms'" 
    [showTransformOptions]="'translateX(100%)'"  />