import { Injectable } from '@angular/core';
import { environment } from 'src/environmen';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class WsColmenaInformesService {
  constructor(public http: HttpClient) {}

  ProspectosporComercial(
    id_empresa: number,
    usuario: string
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          environment.apiUrlV1 +
            `informes/ListaContactos?id_empresa=${id_empresa}&usuario=${usuario}`
        )

        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
