<nav class="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
  <div class="container-fluid">
    <div class="nav-start-wrap"></div>
    <div class="nav-end-wrap">
      <ul class="navbar-nav flex-row">
        <li class="nav-item">
          <a
            routerLink="/general"
            class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover"
          >
            <span class="icon">
              <span class="position-relative">
                <span class="feather-icon">
                  <i data-feather="settings"></i>
                </span>
                <span
                  class="badge badge-sm badge-soft-primary badge-sm badge-pill position-top-end-overflow-1"
                  >4</span
                >
              </span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLink="/inbox"
            class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover"
          >
            <span class="icon">
              <span class="position-relative">
                <span class="feather-icon">
                  <i data-feather="inbox"></i>
                </span>
                <span
                  class="badge badge-sm badge-soft-primary badge-sm badge-pill position-top-end-overflow-1"
                  >4</span
                >
              </span>
            </span>
          </a>
        </li>

        <li class="nav-item">
          <div class="dropdown ps-2">
            <a
              class="dropdown-toggle no-caret"
              href="#"
              role="button"
              data-bs-display="static"
              data-bs-toggle="dropdown"
              data-dropdown-animation
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <div
                class="avatar avatar-primary avatar-sm avatar-rounded avatar-xs"
              >
                <span class="initial-wrap">{{ getInitials(nombres) }}</span>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="p-2">
                <div class="media">
                  <div class="media-head me-2">
                    <div class="avatar avatar-primary avatar-sm avatar-rounded">
                      <span class="initial-wrap">{{
                        getInitials(nombres)
                      }}</span>
                    </div>
                  </div>
                  <div class="media-body">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="d-block dropdown-toggle link-dark fw-medium"
                        data-bs-toggle="dropdown"
                        data-dropdown-animation
                        data-bs-auto-close="inside"
                        >{{ nombres }}</a
                      >
                    </div>
                    <div class="fs-10 lowercase">{{ correo }}</div>
                    <a
                      (click)="DoLogin()"
                      class="cursor-pointer d-block fs-8 link-secondary"
                    >
                      <u>Salir</u>
                    </a>
                  </div>
                </div>
              </div>
              <div class="dropdown-divider"></div>

              <a class="dropdown-item" routerLink="/general">
                <span class="dropdown-icon feather-icon">
                  <i data-feather="settings"></i>
                </span>

                <span>Configuraciones</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
