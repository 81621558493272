import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class WsColmenaInformesService {
  constructor(public http: HttpClient) { }

  ProspectosporComercial(params: any): Promise<any> {
    const id_empresa = Number(localStorage.getItem('id_empresa'));
    return this.http
      .get(`${environment.apiUrlV1}informes/ListaContactos`, {
        params: { ...params, id_empresa },
      })
      .toPromise();
  }

}
