import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
//import { SidebarModule } from 'primeng/sidebar';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    //SidebarModule
  ],
  
  declarations: [
    //GridDocumentsComponent,
    //GridUserComponent,
    //NotAvailableComponent,
    //LoadGridComponent,
    //EmptyGridComponent,
    //GridContactoComponent,
    ///GridConfigContactoComponent
  ],
  exports: [
  ]
})
export class ComponentsModule { }
