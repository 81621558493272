<div class="hk-menu">
    <!-- Brand -->
    <div class="menu-header">
        <span>
            <a class="navbar-brand" href="../home">
                <img class="brand-img img-fluid" src="../../../assets/images/clientyx_blanc_isotipo.png" alt="brand"
                    style="width: 50px;" />
                <img class="brand-img img-fluid" src="../../../assets/images/clientyx_banco_name.png" alt="brand"
                    style="width: 100px;" />
            </a>
            <button class="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle">
                <span class="icon">
                    <span class="svg-icon fs-5">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-bar-to-left"
                            width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                            fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="10" y1="12" x2="20" y2="12"></line>
                            <line x1="10" y1="12" x2="14" y2="16"></line>
                            <line x1="10" y1="12" x2="14" y2="8"></line>
                            <line x1="4" y1="4" x2="4" y2="20"></line>
                        </svg>
                    </span>
                </span>
            </button>
        </span>
    </div>
    <!-- /Brand -->

    <!-- Main Menu -->
    <div data-simplebar class="sidebar-body">
        <div class="menu-content-wrap">
            <div class="menu-group">
                <ul class="navbar-nav flex-column">
                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/home">
                            <span class="nav-icon-wrap">
                                <span class="svg-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-tabler icon-tabler-template" width="24" height="24"
                                        viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <rect x="4" y="4" width="16" height="4" rx="1" />
                                        <rect x="4" y="12" width="6" height="8" rx="1" />
                                        <line x1="14" y1="12" x2="20" y2="12" />
                                        <line x1="14" y1="16" x2="20" y2="16" />
                                        <line x1="14" y1="20" x2="20" y2="20" />
                                    </svg>
                                </span>
                            </span>
                            <span class="nav-link-text">Principal</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="menu-gap"></div>
            <div class="menu-group">
                <div class="nav-header">
                    <span>Configuraciones</span>
                </div>
                <ul class="navbar-nav flex-column">

                    <li class="nav-item">
                        <a class="nav-link" routerLink="/general">
                            <span class="nav-icon-wrap">
                                <span class="svg-icon"></span>
                            </span>
                            <span class="nav-link-text">Principal</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/config_contacto">
                            <span class="nav-icon-wrap">
                                <span class="svg-icon"></span>
                            </span>
                            <span class="nav-link-text">Contactos</span>
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" routerLink="/teams">
                            <span class="nav-icon-wrap">
                                <span class="svg-icon"></span>
                            </span>
                            <span class="nav-link-text">Catalogos</span>
                        </a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/teams">
                            <span class="nav-icon-wrap">
                                <span class="svg-icon"></span>
                            </span>
                            <span class="nav-link-text">Equipos y Usuarios</span>
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" routerLink="/teams">
                            <span class="nav-icon-wrap">
                                <span class="svg-icon"></span>
                            </span>
                            <span class="nav-link-text">Configuración</span>
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
    <!-- /Main Menu -->
</div>

<!--nav class="sidebar">
    <div class="sidebar-header">
        <a  class="sidebar-brand">
            Colmena<span>CRM</span>
        </a>
        <div class="sidebar-toggler not-active">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="sidebar-body">
        <ul class="nav">
            <li class="nav-item nav-category text-base pb-4 capitalize">Configuraciones</li>
            <li class="nav-item nav-category text-sm capitalize height-auto">Ajustes Iniciales</li>
            <li class="nav-item">
                <a href="../team" class="nav-link-config ">
                    <span class="link-title ml-2 white-space-normal">Principal</span>
                </a>
            </li>            
            <li class="nav-item">
                <a href="../team" class="nav-link-config ">
                    <span class="link-title ml-2 white-space-normal">Catalogos</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="../team" class="nav-link-config ">
                    <span class="link-title ml-2 white-space-normal">Notificaciones</span>
                </a>
            </li>
            <li class="nav-item nav-category text-sm capitalize height-auto">Personalización de la Cuenta</li>
            <li class="nav-item">
                <a href="../teams" class="nav-link-config ">
                    <span class="link-title ml-2 white-space-normal">Equipos y Usuarios</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="../team" class="nav-link-config">
                    <span class="link-title ml-2 white-space-normal">Configuración por Defecto de la Cuenta</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link-config" data-bs-toggle="collapse" href="#gestor_tarea" role="button" aria-expanded="false"
                    aria-controls="gestor_tarea">
                    <span class="link-title ml-2 white-space-normal">Integraciones</span>
                    <i class="link-arrow" data-feather="chevron-down"></i>
                </a>
                <div class="collapse" id="gestor_tarea">
                    <ul class="nav sub-menu">
                        <li class="nav-item">
                            <a  href="../widgets"  class="nav-link">Todas las aplicaciones</a>
                        </li>
                        <li class="nav-item">
                            <a href="../calificados" class="nav-link">Calificados</a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item">
                <a href="../team" class="nav-link-config">
                    <span class="link-title ml-2 white-space-normal">Perfiles y Permisos</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="../team" class="nav-link-config">
                    <span class="link-title ml-2 white-space-normal">Seguridad</span>
                </a>
            </li>
            <li class="nav-item nav-category text-sm capitalize height-auto">Manejo de Datos</li>
            <li class="nav-item">
                <a href="../config_contacto" class="nav-link-config">
                    <span class="link-title ml-2 white-space-normal">Contactos</span>
                </a>
            </li>
        </ul>
    </div>
</nav-->