<div class="content-widget-process">

    <section
        class="template-section template-section-spaced-vertical">
        <div class="next-spaced-vertil">
            <div class="next-spaced">
                <main class="main main-flush">
                    <div>
                        <div>
                            <div>
                                <div
                                    class="alert alert_element alert-info alert-info" role="alert">
                                    <div class="alert_inner">
                                        <h5 class="jNwKmT private-alert__title">
                                            En este momento estamos registrando los permisos otorgados con Google Gmail.
                                        </h5>
                                        <div class="alert_body vertical_spacing">
                                            <div>
                                                <div
                                                    class="UIFlex__StyledFlex-sc-1vtdnkq-0 cEsWi flex UIButtonWrapper__FlexContainer-dvknq6-0 dCSvfm">
                                                    <span>
                                                        Permítenos un momento. Una vez finalizado el registro, te redireccionaremos. Por favor, no cambies ni cierres la pantalla.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="element-present">
                            <div class="row  row-content-text">
                                <div class="col col-sm-12 col-md-6">
                                    <div 
                                        class="content-text-element flex"
                                        style="max-width: 370px;">
                                        <h1 class="mb-3 dMNEwg">
                                           Clientyx + Gmail
                                        </h1>
                                        La sincronización bidireccional entre el Correo de Google y Clientyx te permite realizar varias acciones simultáneas. Permite verificar tu buzon de correo y emitir nuevos correos a tus contactos.
                                    </div>
                                    <div>
                                        <p-progressBar mode="indeterminate" styleClass="load-widgets" [style]="{ width:'380px', height: '6px', margintop: '50px' }"></p-progressBar>
                                    </div>
                                </div>
                                <div class="col col-sm-12 col-md-6">
                                    <div><img alt="" class="image image-responsive"
                                            src="../../../../../assets/images/clientyx-gmail.jpg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </section>

</div>