<div>
    <form [formGroup]="form" (ngSubmit)="GenerarRegistroCalendario()">
        <p-sidebar (onHide)="onHideSidebar()" [style]="{'overflow-y': 'hidden'}" [closeOnEscape]="false"
            [showCloseIcon]="false" [blockScroll]="true" [(visible)]="visible" position="right" styleClass="w-30rem">

            <ng-template pTemplate="header">Agenda</ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="IsLoadPages ; else PermisoVerDenegado">
                    <div class="py-3">
                        <div>
                            <div class="pb-1 form_label_wrapper">
                                <label class="form_label">
                                    <span>Título</span>
                                </label>
                            </div>
                            <div class="form_input_wrapper">
                                <input pAutoFocus [autofocus]="true" type="text" pInputText
                                    class="form-control input-focus" formControlName="titulo" />
                            </div>
                        </div>
                    </div>
                    <div class="p-x-5">
                        <div class="pb-2 mb-2">
                            <div class="wrapper_flex_container_star flex">
                                <div class="flex-row width-100"></div>
                                <div class="flex flex-row">
                                    <div class="flex align-items-center">
                                        <div class="mr-3 private-form__set--flush">
                                            <div class="pb-1 form_label_wrapper">
                                                <label class="timeline-microcopy">
                                                    Fecha
                                                </label>
                                            </div>
                                            <div class="form_input_wrapper">
                                                <p-calendar dateFormat="dd/mm/yy" [iconDisplay]="'input'"
                                                    formControlName="FechaRegisterCalendar"
                                                    [showIcon]="true"></p-calendar>
                                            </div>
                                            <div class="private-form__meta">
                                                <div class="private-form__messages"></div>
                                            </div>
                                        </div>
                                        <div class="mr-3 private-form__set--flush">
                                            <div class="pb-1 form_label_wrapper">
                                                <label class="timeline-microcopy">
                                                    <span class="UIFormControl__StyledSpan-sc-6oez18-2 iFkcAf">
                                                        Hora inicio
                                                    </span>
                                                </label>
                                            </div>
                                            <div class="form_input_wrapper">
                                                <div class="private-clickable private-button button-link private-button__dropdown-opener private-timepicker"
                                                    tabindex="0">
                                                    <!--p-dropdown
                                                        formControlName="HoraRegisterInit"
                                                        optionLabel="name" 
                                                        placeholder="">
                                                        <ng-template pTemplate="dropdownicon">
                                                            <i class="pi pi-clock pointer-events-none"></i>
                                                        </ng-template>
                                                    </p-dropdown-->
                                                    <p-calendar [timeOnly]="true" [showTime]="true" hourFormat="24"
                                                        [iconDisplay]="'input'" formControlName="HoraRegisterInit"
                                                        [showIcon]="true" inputId="templatedisplay">
                                                        <ng-template pTemplate="inputicon">
                                                            <i class="pi pi-clock pointer-events-none"></i>
                                                        </ng-template>
                                                    </p-calendar>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="private-form__set--flush">
                                            <div>
                                                <div class="pb-1 form_label_wrapper">
                                                    <label class="timeline-microcopy">
                                                        Hora fin
                                                    </label>
                                                </div>
                                                <div class="form_input_wrapper">
                                                    <div class="private-clickable private-button button-link private-timepicker"
                                                        tabindex="0">
                                                        <p-calendar [timeOnly]="true" [showTime]="true" hourFormat="24"
                                                            [iconDisplay]="'input'" formControlName="HoraRegisterFinish"
                                                            [showIcon]="true" inputId="templatedisplay">
                                                            <ng-template pTemplate="inputicon">
                                                                <i class="pi pi-clock pointer-events-none"></i>
                                                            </ng-template>
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-row width-100">
                                    <div class="">
                                        <div class="pb-1 form_label_wrapper">
                                            <label
                                                class="form_label UIFormControl__InlineFormLabel-sc-6oez18-0 bUjnyd timeline-microcopy">
                                                <span class="UIFormControl__StyledSpan-sc-6oez18-2 iFkcAf">Asistentes
                                                    (2)</span>
                                            </label>
                                        </div>
                                        <div class="form_input_wrapper">
                                            <p-multiSelect [group]="true" styleClass="MultiSelectAsistente"
                                                panelStyleClass="border-syle-select" [style]="{ width: '100%' }"
                                                [options]="NgModelAsistente" formControlName="AsistenteValueSelect"
                                                defaultLabel="Seleccionar Asistentes" [showToggleAll]="false"
                                                [showClear]="false" optionLabel="name" display="chip">

                                                <ng-template let-element pTemplate="item">
                                                    <div [class.disabled-checkbox]="isDisabled(element.code)"
                                                        class="disabled-checkbox flex align-items-center gap-2">
                                                        <label>
                                                            <div>{{ element.name }}</div>
                                                            <small
                                                                class="microcopy private-typeahead-results-item__help is--text--disabled">
                                                                {{ element.code }}
                                                            </small>
                                                        </label>
                                                    </div>
                                                </ng-template>
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-x-5">
                        <div class="pb-1 mb-1">
                            <div class="wrapper_flex_container_star flex">
                                <p-checkbox value="" [trueValue]="true" [falseValue]="false" [binary]="true" formControlName="LinkConferencia" inputId="lm" />
                                <label class="ml-2" for="lm">Agregar Link para video conferencia</label>
                            </div>
                        </div>
                    </div>
                    <div class="display-flex flex-column p-x-4">
                        <div>
                            <div class="StackableRte__StyledEditor-amzsd2-0 cRCsaJ">
                                <div class="private-form__set">
                                    <div>
                                        <div class="pb-1 form_label_wrapper">
                                            <label class="form_label">
                                                <span>
                                                    Descripción de agenda
                                                </span>
                                            </label>
                                        </div>
                                        <div class="form_input_wrapper">
                                            <p-editor formControlName="descripcion_agenda"
                                                [style]="{ height: '320px' }"></p-editor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #PermisoVerDenegado>
                    <app-loader-sidebar></app-loader-sidebar>
                </ng-template>


            </ng-template>
            <ng-template pTemplate="footer">
                <div>
                    <div class="flex">
                        <button type="submit"  [disabled]="form.invalid || procesando" class="button_static button-primary button-sm">

                            <span *ngIf="!procesando">Guardar</span>
                            <!--span *ngIf="procesando">Procesando </span>
                            <span *ngIf="procesando" class="fa fa-spinner fa-spin"></span-->
                            <fa-icon *ngIf="procesando" class="color-spinner" [icon]="faCircleNotch" size="1x" animation="spin" ></fa-icon>
                            
                        </button>
                        <button (click)="toggleSidebarAgenda()" class="button_static button-secondary button-sm"
                            type="button">
                            Cancelar
                        </button>
                    </div>
                </div>
            </ng-template>

        </p-sidebar>
    </form>
</div>