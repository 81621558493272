import { Component } from '@angular/core';

@Component({
  selector: 'app-postventa',
  templateUrl: './postventa.component.html',
  styleUrls: ['./postventa.component.css']
})
export class PostventaComponent {

}
