<div>
    <div class="AbstractToolbar__Toolbar-sc-7wg1au-0 dkRljM UIFilterBar__FilterBar-sc-1seargs-0 fAeUVJ" role="group">
        <div class="AbstractToolbar__ToolbarInner-sc-7wg1au-1 fzkobS">
            
        </div>
    </div>
    <div data-test-id="teams-table">
        <div class="UISplitLayout__Outer-sc-1mvjymx-0 dmqAgX UIEmptyState__Outer-ve1rv5-0 jcnEkn p-top-6">
            <div direction="row" wrap="wrap"
                class="UIFlex__StyledFlex-k81u9r-0 gENmok flex UISplitLayout__Inner-sc-1mvjymx-1 UIEmptyState__Inner-ve1rv5-1 jncyiN">
                <div orientation="horizontal" order="0"
                    class="UIBox__Box-ufnis-0 emfkTi private-flex__child UISplitLayout__PrimarySlot-sc-1mvjymx-2 UIEmptyState__PrimarySlot-ve1rv5-2 eXsflE">
                    <h4 aria-level="2" class="Heading-sc-1sayz3p-0 H4-sc-1oirt7m-0 hyvA-dJ">
                        <p data-locale-at-render="es" data-key="usersManager.teamPqlState.title">La funcionalidad no está disponible en este momento.</p>
                    </h4>
                    <div>
                        <p class="pb-4">Estamos trabajando arduamente para ofrecerte nuevas funcionalidades en la plataforma.</p>
                        <p class="pb-4">
                            <span>¿Deseas más información? 
                                <a class=""
                                    href="#" target="_blank">Lee acerca de los equipos
                                    <span
                                        aria-label="El enlace se abre en una ventana nueva" role="img"
                                        class=" icon_button  UILink__DefaultExternalIcon-sc-7smrsm-0 kyrPim"
                                        data-icon-name="externalLink">
                                        <span aria-hidden="true"
                                            class="UIIcon__IconContent-sc-1sjri73-0 ezLgqM">
                                        </span>
                                    </span>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
                <div orientation="horizontal"
                    class="UIBox__Box-ufnis-0 jSxMkM private-flex__child UISplitLayout__SecondarySlot-sc-1mvjymx-3 UIEmptyState__SecondarySlot-ve1rv5-3 bCwHpd">
                    <img width="100%" alt=""
                        class="private-image private-image--object-fit-contain private-illustration"
                        src="../../../assets/images/icon_trabajando06.png"></div>
            </div>
        </div>
    </div>
</div>