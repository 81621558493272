import { Component } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-app-load-grid',
  templateUrl: './app-load-grid.component.html',
  styleUrl: './app-load-grid.component.css'
})
export class AppLoadGridComponent {

  faCoffee = faCoffee;
  faSpinner = faSpinner;
  faCircleNotch = faCircleNotch;

}
