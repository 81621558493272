import { Component } from '@angular/core';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrl: './document-preview.component.css'
})
export class DocumentPreviewComponent {

}
