import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environmen';

@Injectable({
  providedIn: 'root'
})
export class WsColmenaLoginService {

  constructor(public http: HttpClient) { }


  LoginColmena(data_login: any) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiURlv1Login + 'login', data_login).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          //
          console.log(error);
          reject(error);
        }
      );
    });
  }
}
